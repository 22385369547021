import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export const useTracking = (trackingId) => {
  const { listen } = useHistory();
  useEffect(() => {
    console.log("creating the useTracking listen function");
    const unlisten = listen((location) => {
      console.log("running the useTracking listen function");
      if (!window.gtag) return;
      if (!trackingId) {
        console.log(
          "Google tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`."
        );
        return;
      }
      console.log("Google tracking is enabled with tag: " + trackingId);
      window.gtag("config", trackingId, {
        page_path: location.pathname,
        page_title: location.pathname,
      });
    });
    return unlisten;
  }, [trackingId, listen]);
};
