import React from "react";
import { connect } from "react-redux";
//import { ProgressBar } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { noDecimalCurrencyFormatter } from "../../utils/currencyFormatter";
//import percentFormatter from "../../utils/percentFormatter";
import { categoryFilterSlice } from "../../slices/categoryFilterSlice.js";

import childCareLogo from "../../img/spend-plan/childcare.svg";
// No categories that match this yet
//import crossLogo from "../../img/spend-plan/cross.svg";
import financialLogo from "../../img/spend-plan/wallet.svg";
// Looks like the dollar logo is black line art instead of white like the others
import dollarLogo from "../../img/spend-plan/dollar-sign.svg";
import educationLogo from "../../img/spend-plan/education.svg";
import entertainmentLogo from "../../img/spend-plan/entertainment.svg";
//import foodLogo from "../../img/spend-plan/food-background.svg";
// No categories that match this yet
//import giftsLogo from "../../img/spend-plan/gifts.svg";

// Looks like the healthcareLogo is black line art instead of white like the others
//import healthcareLogo from "../../img/spend-plan/healthcare.svg";
// No categories that match this yet
//import healthLogo from "../../img/spend-plan/health.svg";
import housingLogo from "../../img/spend-plan/housing.svg";
import restaurantLogo from "../../img/spend-plan/restcafe.svg";
import shoppingLogo from "../../img/spend-plan/shopping.svg";
import groceriesLogo from "../../img/spend-plan/groceries.svg";
import insuranceLogo from "../../img/spend-plan/health.svg";
import miscellaneousLogo from "../../img/spend-plan/miscellaneous.svg";
import personalLogo from "../../img/spend-plan/personal.svg";
import savingsLogo from "../../img/spend-plan/savings.svg";
import transportationLogo from "../../img/spend-plan/transportation.svg";
import utilitiesLogo from "../../img/spend-plan/utilities.svg";
// Looks like the wallet logo is black line art instead of white like the others
//import walletLogo from "../../img/spend-plan/wallet.svg";

const SpendCategoryCard = (props) => {
  const history = useHistory();

  const onCardClick = (categoryName) => {
    props.setCategory(categoryName);
    history.push("/history");
  };

  //let budgetAmount = 0;
  let spentAmount = 0;
  /*let percentageNumber = 0;
  let mainValueDisplay = "";
  let subValueDisplay = "";
  let colorBackgroundClass = "";*/
  let thisCategoryLogo = miscellaneousLogo;

  // if (props.budgetAmount) {
  //   budgetAmount = props.budgetAmount;
  // }
  if (props.spentAmount) {
    spentAmount = props.spentAmount;
  }

  // if (budgetAmount !== 0) {
  //   percentageNumber = spentAmount / budgetAmount;
  // }

  if (props.categoryName === "Income") {
    thisCategoryLogo = dollarLogo;
  }
  if (props.categoryName === "Housing") {
    thisCategoryLogo = housingLogo;
  }
  if (props.categoryName === "Restaurant/Takeout") {
    thisCategoryLogo = restaurantLogo;
  }
  if (props.categoryName === "Transportation") {
    thisCategoryLogo = transportationLogo;
  }
  if (props.categoryName === "Groceries") {
    thisCategoryLogo = groceriesLogo;
  }
  if (props.categoryName === "Utilities") {
    thisCategoryLogo = utilitiesLogo;
  }
  if (props.categoryName === "Shopping") {
    thisCategoryLogo = shoppingLogo;
  }
  // Note there are two possible logos here
  if (props.categoryName === "Insurance & Healthcare") {
    thisCategoryLogo = insuranceLogo;
  }
  if (props.categoryName === "Financial") {
    thisCategoryLogo = financialLogo;
  }
  if (props.categoryName === "Personal") {
    thisCategoryLogo = personalLogo;
  }
  if (props.categoryName === "Education") {
    thisCategoryLogo = educationLogo;
  }
  if (props.categoryName === "Child Care") {
    thisCategoryLogo = childCareLogo;
  }
  if (props.categoryName === "Savings") {
    thisCategoryLogo = savingsLogo;
  }
  if (props.categoryName === "Entertainment") {
    thisCategoryLogo = entertainmentLogo;
  }
  /*if (props.underBudget) {
    colorBackgroundClass = "dark-green-background";
    mainValueDisplay = noDecimalCurrencyFormatter.format(
      budgetAmount - spentAmount
    );
    if (budgetAmount !== 0 || spentAmount === 0) {
      subValueDisplay = percentFormatter.format(percentageNumber);
      percentageNumber *= 100;
    } else {
      subValueDisplay = "--";
    }
  } else {
    // this is an over budget category
    colorBackgroundClass = "brown-yellow-background";
    subValueDisplay = noDecimalCurrencyFormatter.format(spentAmount);
    if (budgetAmount !== 0 || spentAmount === 0) {
      mainValueDisplay = percentFormatter.format(percentageNumber);
      percentageNumber *= 100;
    } else {
      mainValueDisplay = "--";
    }
  }

  const budgetAmountDisplay = noDecimalCurrencyFormatter.format(budgetAmount);*/

  const spentAmountDisplay = noDecimalCurrencyFormatter.format(spentAmount);

  return (
    <div
      onClick={() => onCardClick(props.categoryName)}
      className="card card-blog"
    >
      <div className="card-body text-center">
        <div className="circleIcon">
          <img className="img " alt="Category Name" src={thisCategoryLogo} />
        </div>
        <h3>{props.categoryName}</h3>
        <p className="largeNumber">
          {spentAmountDisplay}
          <span className="small bottom">Spend</span>
        </p>
        <p>
          {props.transactionCount ? props.transactionCount : 0} transactions
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  budgets: state.auth.budgets,
  categoryMap: state.auth.categoryMap,
  spendingByCategory: state.plaid.spendingByCategory,
});

const { setCategory } = categoryFilterSlice.actions;
const mapDispatchToProps = { setCategory };

// Note that there is probably a better way to do this with React hooks now
export default connect(mapStateToProps, mapDispatchToProps)(SpendCategoryCard);
