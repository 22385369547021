import React, { useState } from "react";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import Datetime from "react-datetime"; // https://github.com/arqex/react-datetime
import "react-datetime/css/react-datetime.css";
import MUIDataTable from "mui-datatables"; // https://github.com/gregnb/mui-datatables
import moment from "moment";
import { currencyFormatter } from "../../utils/currencyFormatter.js";
import Container from "react-bootstrap/Container";
import Loading from "../../utils/loading.js";
import { categoryFilterSlice } from "../../slices/categoryFilterSlice.js";
//import { updateSortedCategories } from "../../utils/processTransactionList.js";

import axios from "axios";
import setAxiosAuth from "../../utils/setAxiosAuth.js";

const HistoricalTable = (props) => {
  let propHideCategories = props.shouldHideCategories
    ? props.shouldHideCategories
    : false;
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [transactionsData, setTransactions] = useState([]);
  const [whichMonth, setWhichMonth] = useState("Last 30 days");
  const [incomingSum, setIncomingSum] = useState(0.0);
  const [outgoingSum, setOutgoingSum] = useState(0.0);
  const { plaid, categoryFilter } = props;
  const { transactions, incomeSum, spendingSum } = plaid;

  const makeTableData = (input) => {
    let tempTable = [];
    input.forEach(function (account) {
      account.transactions.forEach(function (transaction) {
        const pushTransaction = {
          isReviewed: transaction.isReviewed + "",
          isDuplicate: transaction.isDuplicate + "",
          account: account.accountName,
          date: transaction.date,
          category: transaction.category[0],
          name: transaction.name.substring(0, 20),
          amount: currencyFormatter.format(transaction.amount * -1),
          transaction_id: transaction.transaction_id,
          rawName: transaction.name,
          merchantName: transaction.merchant_name,
          isPending: transaction.pending,
        };
        if (transaction.merchant_name) {
          pushTransaction.name = transaction.merchant_name.substring(0, 20);
        }
        tempTable.push(pushTransaction);
      });
    });
    return tempTable;
  };

  React.useEffect(() => {
    setTransactions(makeTableData(transactions));
  }, [transactions]);

  React.useEffect(() => {
    setIncomingSum(incomeSum);
  }, [incomeSum]);

  React.useEffect(() => {
    setOutgoingSum(spendingSum);
  }, [spendingSum]);

  // Get Historical transactions and budget with Auth0
  const getHistory = (startDate) => {
    console.log("In getHistory, whichMonth is " + whichMonth);
    setWhichMonth(moment(startDate).format("MMM YYYY"));

    getAccessTokenSilently().then((accessToken) => {
      // Note that server will process requests for dates assuming UTC, so strip timezone when requesting
      const plaidData = {
        accounts: props.plaid.accounts,
        startDate: moment(startDate).utcOffset(0),
        endDate: moment(startDate).utcOffset(0).endOf("month"),
      };
      if (accessToken) {
        setAxiosAuth(accessToken);
      }
      if (plaidData && plaidData.accounts.length !== 0) {
        const promiseArray = [];
        // This call will load the historical transaction data
        promiseArray.push(
          axios
            .post("/api/plaid/accounts/history/transactions", plaidData)
            .then((res) => {
              const resExpenseSum = res.data.expenseSum;
              const resPaycheckSum = res.data.paycheckSum;
              const resOtherIncomeSum = res.data.otherIncomeSum;
              const resIncomeSum = resPaycheckSum + resOtherIncomeSum;

              // Need to check if there are transactions?
              if (res.data.transactions) {
                setTransactions(makeTableData(res.data.transactions));
              }
              if (resExpenseSum) {
                setOutgoingSum(resExpenseSum);
              } else {
                setOutgoingSum(0.0);
              }
              if (resIncomeSum) {
                setIncomingSum(resIncomeSum);
              } else {
                setIncomingSum(0.0);
              }
              if (!res.data.transactions || !res.data.transactions.length) {
                // Should throw no a tranasactions error here
                console.log("no transaction history found");
              }
              return res.data;
            })
            .catch((err) => {
              console.log(err);
            })
        );
      }
    });
  };

  const transactionMUIColumns = [
    { label: "Date", name: "date", options: { filter: false } },
    {
      label: "Account",
      name: "account",
      options: {
        filter: false,
      },
    },
    {
      label: "Name",
      name: "name",
      options: {
        filter: false,
      },
    },
    {
      label: "Amount",
      name: "amount",
      options: {
        filter: true,
        filterType: "dropdown",
        filterOptions: {
          names: ["Outgoing Money", "Incoming Money"],
          logic(amount, filterVal) {
            amount = amount.replace(/\$|\,/g, "");
            const show =
              (filterVal.indexOf("Outgoing Money") >= 0 && amount <= 0) ||
              (filterVal.indexOf("Incoming Money") >= 0 && amount > 0);
            return !show;
          },
        },
      },
    },
    {
      label: "Category",
      name: "category",
      options: {
        filter: true,
        filterList: categoryFilter,
        customFilterListOptions: { render: (v) => `Category: ${v}` },
      },
    },
    /*{
      label: "Marked Dupe/Ignore?",
      name: "isDuplicate",
      options: {
        filter: false,
        search: false,
      },
    },*/
    {
      label: "Transaction ID",
      name: "transaction_id",
      options: {
        display: false,
        filter: false,
        searchable: false,
        sort: false,
      },
    },
    {
      label: "Raw Transaction Name",
      name: "rawName",
      options: {
        display: false,
        filter: false,
        // Note, ideally we'd be able to search this hidden column
        // but I think it is a limitation of the default search logic
        // https://github.com/gregnb/mui-datatables/issues/522
        searchable: false,
      },
    },
  ];
  if (propHideCategories) {
    let findIndex = -1;
    for (let a = 0; a < transactionMUIColumns.length; a++) {
      let thisCol = transactionMUIColumns[a];
      if (thisCol.name === "category") {
        findIndex = a;
        break;
      }
    }
    if (findIndex !== -1) {
      transactionMUIColumns.splice(findIndex, 1);
    }
  }

  return !isAuthenticated ||
    plaid.accountsLoading ||
    plaid.transactionsLoading ? (
    <Loading />
  ) : plaid.accounts.length > 1 ? (
    <>
      <div className="section center">
        <Container>
          <span className="small"> Choose timeframe</span>
          <Datetime
            initialViewMode="months"
            timeFormat={false}
            initialValue={moment().startOf("month")}
            onChange={getHistory}
            dateFormat="MMM y"
            closeOnSelect={true}
            className="date"
          />
          <br />
          {whichMonth +
            ", total incoming money: " +
            currencyFormatter.format(incomingSum)}
          <br />
          {whichMonth +
            ", total outgoing money: " +
            currencyFormatter.format(outgoingSum)}

          <br />
          {!propHideCategories ? (
            <button onClick={() => props.setCategory("Income")}>
              Show Income
            </button>
          ) : (
            <></>
          )}
        </Container>
      </div>
      <Container>
        <MUIDataTable
          title={"Historical Transactions"}
          data={transactionsData}
          columns={transactionMUIColumns}
          options={{
            filter: true,
            // Note, in activity page we are using column index values, may not want to enable view/hide columns there
            viewColumns: false,
            filterType: "checkbox",
            selectableRows: "none",
            sortOrder: { name: "date", direction: "desc" },
            rowsPerPage: 100,
            downloadOptions: {
              filename: "waiwai-export.csv",
              filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
              },
            },
          }}
        />
      </Container>
    </>
  ) : (
    <></>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  plaid: state.plaid,
  categoryFilter: state.categoryFilter,
});

const { setCategory } = categoryFilterSlice.actions;
const mapDispatchToProps = { setCategory };

// Note that there is probably a better way to do this with React hooks now
export default connect(mapStateToProps, mapDispatchToProps)(HistoricalTable);
