import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import accountReducer from "./accountReducer";
import { categoryFilterSlice } from "../slices/categoryFilterSlice.js";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  plaid: accountReducer,
  categoryFilter: categoryFilterSlice.reducer,
});
