export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const ADD_ACCOUNT = "ADD_ACCOUNT";
export const REFRESH_ACCOUNT = "REFRESH_ACCOUNT";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const GET_ACCOUNTS = "GET_ACCOUNTS";
export const ACCOUNTS_LOADING = "ACCOUNTS_LOADING";
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const TRANSACTIONS_LOADING = "TRANSACTIONS_LOADING";
export const SET_BUDGETS = "SET_BUDGETS";
export const SET_SPEND_RANGE_DAYS_SELECTED = "SET_SPEND_RANGE_DAYS_SELECTED";
export const SET_TRANSACTION_DATA = "SET_TRANSACTION_DATA";
export const USER_FIRST_VISIT = "USER_FIRST_VISIT";
export const GET_USER_INFO = "GET_USER_INFO";
export const GET_APP_CATEGORY_LIST = "GET_APP_CATEGORY_LIST";
export const SET_CURRENT_ON_BEHALF_USER = "SET_CURRENT_ON_BEHALF_USER";
