import React from "react";
import { connect } from "react-redux";
import { withAuth0 } from "@auth0/auth0-react";
import { getAccounts } from "../../actions/accountActions.js";
import { getUserInfo, setCurrentUser } from "../../actions/authActions.js";

// We use a class component and put logic in the constructor to make sure the bootstrap just happens once
// This is also where we can set our redux state for the logged in user
class AccountBootstrap extends React.Component {
  auth0Namespace =
    process.env.REACT_APP_AUTH0_AUDIENCE || "https://waiwai/auth";

  constructor(props) {
    super(props);
    const { getAccessTokenSilently, user } = this.props.auth0;
    const { accounts, getAccounts, getUserInfo, setCurrentUser, accountsLoading } = this.props;
    // Our Auth0 setup has a rule to always put user_data into id-tokens
    const userData = user[this.auth0Namespace + "/user_data"];
    const appData = user[this.auth0Namespace + "/app_metadata"];

    let dataToSend = userData;
    // We have stopped using the preferredName field from auth0 now
    // that mlp-client uses the mano-wai backend (user.name will have the preferredName)
    dataToSend = { ...userData, preferredName: user.name };
    // Note that we're storing the appData inside normal user_data spot
    dataToSend.appData = appData;

    if (accounts && accounts.length === 0 && !accountsLoading) {
      setCurrentUser(dataToSend);
      getAccessTokenSilently().then((accessToken) => {
        getUserInfo(accessToken);
        getAccounts(accessToken);
      });
    }
  }
  render() {
    return <></>;
  }
}

const accountStateToProps = (state) => ({
  accounts: state.plaid.accounts,
  accountsLoading: state.plaid.accountsLoading,
});
const accountDispatchToProps = {
  getAccounts,
  getUserInfo,
  setCurrentUser,
};

export default connect(
  accountStateToProps,
  accountDispatchToProps
)(withAuth0(AccountBootstrap));
