import React from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
//import { Redirect } from "react-router-dom";
import Loading from "../../utils/loading.js";
import { PlaidButton, DeleteAccountButton } from "../../utils/plaidButton.js";
import ColorHeader from "../layout/ColorHeader";

const BankAccountsPage = (props) => {
  const listAccounts = props.accounts.map((account, i) => {
    return (
      <div className="row mb-25" key={account._id}>
        <div className="col bankAccountRow">
          <h5>{account.institutionName}</h5>
          <br />
          {!props.currentOnBehalfUser._id ? (
            <>
              <PlaidButton
                className="Secondary small"
                buttonText="Refresh"
                existingAccount={account}
              />{" "}
              <span className="notButton">
                <DeleteAccountButton account={account} />
              </span>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  });

  const addButtonAndLink = (
    <>
      <div className="row">
        <div className="col ">
          <PlaidButton
            buttonClass={props.accounts.length ? "secondaryButton" : ""}
            buttonText={
              !props.accounts.length
                ? "Connect your bank account"
                : "Connect bank account"
            }
            existingAccount={null}
          />
        </div>

        <div className="col text-right">
          {props.accounts.length && props.transactionsLoading ? (
            <Loading />
          ) : (
            <></>
          )}
          {(props.accounts.length > 1 || props.totalTransactionCount) &&
          !props.transactionsLoading ? (
            <>
              <a href="/manage-transactions">
                {/* For now, going to use a regular link to make sure state gets loaded */}
                {/*<LinkContainer to="manage-transactions">*/}
                <Button>Review Transactions</Button>
                {/*</LinkContainer>*/}
              </a>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );

  if (props.userFirstVisit && !props.currentOnBehalfUser._id) {
    /*return <Redirect to="/orientation" />;*/
  }
  return (
    <>
      <ColorHeader
        mainHeaderText={"Bank Accounts"}
        subHeaderText="Manage your accounts"
        colorClassName="section-header-green"
      />
      <div className="section ">
        <div className="container">
          {props.currentOnBehalfUser._id && props.userFirstVisit ? (
            <span>
              The shared account you are viewing has not added any bank logins
              {props.totalTransactionCount
                ? " but has added cash transactions "
                : " or cash transactions "}
              to Waiwai
            </span>
          ) : (
            <></>
          )}
          {!props.accountsLoading &&
          (props.accounts.length > 1 || props.totalTransactionCount) ? (
            listAccounts
          ) : (
            <></>
          )}
          {!props.currentOnBehalfUser._id ? addButtonAndLink : <></>}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  accounts: state.plaid.accounts,
  totalTransactionCount: state.plaid.totalTransactionCount,
  accountsLoading: state.plaid.accountsLoading,
  transactionsLoading: state.plaid.transactionsLoading,
  userFirstVisit: state.plaid.userFirstVisit,
  currentOnBehalfUser: state.auth.currentOnBehalfUser,
});
const mapDispatchToProps = {};

// Note that there is probably a better way to do this with React hooks now
export default connect(mapStateToProps, mapDispatchToProps)(BankAccountsPage);
