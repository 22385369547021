import React from "react";
import moment from "moment";

class CashTransactionForm extends React.Component {
  constructor(props) {
    super(props);
    // State is setup with all the fields that are needed for a new cash transaction
    this.state = {
      account_id: "Cash",
      pending: false,
      category: ["Essentials", "Personal"],
      date: new moment().format("YYYY-MM-DD"),
      name: "Personal expense",
      amount: 20,
      isIncome: "false",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    const sendObj = { ...this.state };
    if (this.state.isIncome === "true") {
      sendObj.category = ["Income", "Income"];
    }
    if (this.props.sendSubmit) {
      this.props.sendSubmit(sendObj);
    }
    event.preventDefault();
  }

  render() {
    return (
      <form className="addcash row" onSubmit={this.handleSubmit}>
        <div className="col col-sm-2">
          <label>
            Date:
            <input
              name="date"
              type="date"
              value={this.state.date}
              onChange={this.handleInputChange}
            />
          </label>
        </div>
        <div className="col col-sm-2">
          <label>
            Vendor name:
            <input
              name="name"
              type="text"
              value={this.state.name}
              onChange={this.handleInputChange}
            />
          </label>
        </div>
        <div className="col col-sm-2">
          <label>
            <input
              type="radio"
              value="false"
              name="isIncome"
              checked={this.state.isIncome === "false"}
              onChange={this.handleInputChange}
            />
            {" Expense"}
          </label>
        </div>
        <div className="col col-sm-2">
          <label>
            <input
              type="radio"
              value="true"
              name="isIncome"
              checked={this.state.isIncome === "true"}
              onChange={this.handleInputChange}
            />
            {" Income"}
          </label>
        </div>
        <div className="col col-sm-2">
          <label>
            Amount:
            <input
              name="amount"
              type="number"
              step="0.01"
              min="0"
              value={this.state.amount}
              onChange={this.handleInputChange}
            />
          </label>
        </div>
        <div className="col col-sm-2">
          <input type="submit" value="Add" className="btn secondaryButton" />
        </div>
      </form>
    );
  }
}

export default CashTransactionForm;
