import axios from "axios";
import moment from "moment";
import setAxiosAuth from "../utils/setAxiosAuth.js";
import { generateWaiwaiFromTwoLayerCategories } from "../utils/waiwaiCategories.js";

// whichGroupForCategory is part of commented out updateThirtyDayTransaction()
//import { whichGroupForCategory } from "../utils/waiwaiCategories.js";

import {
  ADD_ACCOUNT,
  UPDATE_ACCOUNT,
  REFRESH_ACCOUNT,
  DELETE_ACCOUNT,
  GET_ACCOUNTS,
  ACCOUNTS_LOADING,
  GET_TRANSACTIONS,
  TRANSACTIONS_LOADING,
  USER_FIRST_VISIT,
  GET_APP_CATEGORY_LIST,
} from "./types";

import { processTransactionList } from "../utils/processTransactionList.js";

// Add account
export const addAccount = (accessToken, plaidData) => (dispatch, getState) => {
  const state = getState();
  if (accessToken) {
    setAxiosAuth(accessToken);
  }
  axios
    .post("/api/plaid/accounts/add", plaidData)
    .then((res) => {
      if (res.data.newAccount) {
        // We only need to add the cashAccount manually like this after the first bank account added
        if (
          res.data.cashAccount &&
          state.plaid.accounts &&
          !state.plaid.accounts.length
        ) {
          dispatch({
            type: ADD_ACCOUNT,
            payload: res.data.newAccount,
          });
          dispatch({
            type: ADD_ACCOUNT,
            payload: res.data.cashAccount,
          });
        } else {
          dispatch({
            type: ADD_ACCOUNT,
            payload: res.data.newAccount,
          });
        }
        // Have to return here so that the then below has the data payload of new account
        return { payload: res.data.newAccount };
      } else {
        // No new account returned
        return null;
      }
    })
    .then((data) => {
      if (state.plaid.accounts) {
        return dispatch(
          getTransactions(accessToken, {
            accounts: state.plaid.accounts.concat(data.payload),
            shouldCheckPlaid: true,
          })
        );
      } else {
        return null;
      }
    })
    .catch((err) => console.log(err));
};

// Refresh account
export const refreshAccount = (accessToken, plaidData) => (dispatch) => {
  const id = plaidData.metadata.institution.institution_id;
  const accounts = plaidData.accounts;
  if (accessToken) {
    setAxiosAuth(accessToken);
  }
  axios
    .post(`/api/plaid/accounts/refresh/${id}`, plaidData)
    .then((res) =>
      dispatch({
        type: REFRESH_ACCOUNT,
        payload: id,
      })
    )
    .then((data) =>
      accounts
        ? dispatch(
            getTransactions(accessToken, { accounts, shouldCheckPlaid: true })
          )
        : null
    )
    .catch((err) => console.log(err));
};

// Delete account
export const deleteAccount = (accessToken, plaidData) => (
  dispatch,
  getState
) => {
  const state = getState();
  const { accounts } = state.plaid;
  if (accessToken) {
    setAxiosAuth(accessToken);
  }
  if (window.confirm("Are you sure you want to remove this account?")) {
    const id = plaidData.id;
    const newAccounts = accounts.filter((account) => account._id !== id);
    axios
      .delete(`/api/plaid/accounts/${id}`)
      .then((res) =>
        dispatch({
          type: DELETE_ACCOUNT,
          payload: id,
        })
      )
      .then(
        newAccounts
          ? dispatch(
              getTransactions(accessToken, {
                accounts: newAccounts,
                shouldCheckPlaid: true,
              })
            )
          : null
      )
      .catch((err) => console.log(err));
  }
};

// Get all accounts for specific user with Auth0
export const getAccounts = (accessToken, onBehalfID) => (dispatch) => {
  if (accessToken) {
    setAxiosAuth(accessToken, onBehalfID);
  }
  dispatch(setAccountsLoading());
  axios
    .get("/api/plaid/accounts")
    .then((res) => {
      // Note that res.data.all_hca_categories holds all of the hca categories from the server
      const serverCategories = generateWaiwaiFromTwoLayerCategories(
        res.data.all_new_waiwai_categories
      );
      dispatch({
        type: GET_APP_CATEGORY_LIST,
        payload: serverCategories,
      });
      dispatch({
        type: GET_ACCOUNTS,
        payload: res.data.accounts,
      });
      dispatch(
        getTransactions(accessToken, {
          accounts: res.data.accounts,
          shouldCheckPlaid: true,
        })
      );
    })
    .catch((err) => {
      if (err && err.response && err.response.status === 400) {
        console.log("Error 400 indicates user hasn't added any accounts yet");
        // Because of the case of loginAs, we should still dispatch the update to accounts though
        dispatch({
          type: GET_ACCOUNTS,
          payload: [],
        });
        // We can also try to fetch cash transactions
        dispatch(
          getTransactions(accessToken, {
            accounts: [],
            shouldCheckPlaid: false,
          })
        );
      }
      dispatch({
        type: USER_FIRST_VISIT,
        payload: true,
      });
    });
};

export const setOrientationComplete = () => (dispatch) => {
  dispatch({
    type: USER_FIRST_VISIT,
    payload: false,
  });
};

// Accounts loading
export const setAccountsLoading = () => {
  return {
    type: ACCOUNTS_LOADING,
  };
};

export const addTransaction = (accessToken, inputData) => (
  dispatch,
  getState
) => {
  const state = getState();
  if (accessToken) {
    setAxiosAuth(accessToken);
  }
  return axios
    .post("/api/plaid/accounts/history/transactions/add", inputData)
    .then((res) => {
      console.log("got successful response on adding transaction");
      dispatch(
        getTransactions(accessToken, {
          accounts: state.plaid.accounts,
          shouldCheckPlaid: false,
        })
      );
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

// Get Transactions with Auth0
// Note that currently this action only deals with last 30 day transactions
// ALSO NOTE - right now the list of 30 day plaid and 30 day historical transactions
// should be the same content - but when we support a user adding cash transactions,
// historical db should be preferred (so that is why we switch to the history db)
export const getTransactions = (accessToken, inputData) => (
  dispatch,
  getState
) => {
  const checkState = getState();
  const isLoading =
    checkState.plaid.transactionsLoading || checkState.plaid.accountsLoading;
  if (accessToken) {
    setAxiosAuth(accessToken);
  }
  // We set the utcOffset to 0 to make sure that the date string matches server default timezone
  const endDate = moment().utcOffset(0);
  const startDate = moment(endDate).utcOffset(0).subtract(30, "days");
  let plaidData = inputData;
  if (inputData.shouldCheckPlaid !== undefined) {
    plaidData = inputData.accounts;
  }

  if (plaidData) {
    // NOTE - in the case where a new account was added (or refreshed), need to check plaid
    // for both the possibility of needUpdate and also for the fresh account transactions
    if (inputData.shouldCheckPlaid && !isLoading) {
      //console.log("we are making plaid call");
      dispatch(setTransactionsLoading());
      axios
        .post("/api/plaid/accounts/transactions", plaidData)
        .then((res) => {
          //console.log("Now we are making history call");
          // I think probably need to wait for the Plaid API call to return, then also call historical actually
          axios
            .post("/api/plaid/accounts/history/transactions", {
              accounts: plaidData,
              startDate,
              endDate,
            })
            .then((histResp) => {
              if (histResp.data.transactionCount === 0) {
                // If there is only one account (Cash) and it has no transactions, trigger orientation
                if (plaidData && plaidData.length === 1) {
                  if (plaidData[0].itemId === "Cash") {
                    dispatch({
                      type: USER_FIRST_VISIT,
                      payload: true,
                    });
                  }
                }
              }
              if (histResp.data.transactions) {
                const state = getState();

                dispatch(
                  processTransactionList(
                    histResp.data.transactions,
                    state.auth.budgets,
                    state.plaid.allCategoriesObj,
                    state.plaid.allCategoriesArray,
                    histResp.data.returnGroupsSums,
                    histResp.data.transactionCountPerCategory
                  )
                );
                dispatch({
                  type: GET_TRANSACTIONS,
                  payload: histResp.data.transactions,
                });
              } else {
                // Should throw no a tranasactions error here
                console.log(
                  "No transactions received from historical transaction api"
                );
              }
            });
          /*if (res.data.transactions) {
            const state = getState();
            dispatch(
              processTransactionList(res.data.transactions, state.auth.budgets)
            );
            dispatch({
              type: GET_TRANSACTIONS,
              payload: res.data.transactions,
            });
          } else {
            // Should throw no a tranasactions error here
            console.log("No transactions received from plaid transaction api");
          }*/
          if (res.data.needUpdate && res.data.needUpdate.length) {
            for (let a = 0; a < res.data.needUpdate.length; a++) {
              let account = res.data.needUpdate[a];
              dispatch({
                type: UPDATE_ACCOUNT,
                payload: account,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: GET_TRANSACTIONS,
            payload: null,
          });
        });
    } else {
      // In the case the frontend thinks a plaid check is not needed, just pull from history api
      //console.log("we are not making plaid call, just getting from history db");
      dispatch(setTransactionsLoading());
      axios
        .post("/api/plaid/accounts/history/transactions", {
          accounts: plaidData,
          startDate,
          endDate,
        })
        .then((histResp) => {
          if (histResp.data.transactionCount === 0) {
            // If there is only one account (Cash) and it has no transactions, trigger orientation
            if (plaidData && plaidData.length === 1) {
              if (plaidData[0].itemId === "Cash") {
                dispatch({
                  type: USER_FIRST_VISIT,
                  payload: true,
                });
              }
            }
          }
          if (histResp.data.transactions) {
            const state = getState();
            dispatch(
              processTransactionList(
                histResp.data.transactions,
                state.auth.budgets,
                state.plaid.allCategoriesObj,
                state.plaid.allCategoriesArray,
                histResp.data.returnGroupsSums,
                histResp.data.transactionCountPerCategory
              )
            );
            dispatch({
              type: GET_TRANSACTIONS,
              payload: histResp.data.transactions,
            });
            // This is the case where there are no bank logins, but there are cash transactions
            // because plaidData is just the empty client-side accounts array
            if (
              !plaidData.length &&
              histResp.data.accounts &&
              histResp.data.accounts.length
            ) {
              dispatch({
                type: GET_ACCOUNTS,
                payload: histResp.data.accounts,
              });
            }
          } else {
            // Should throw no a tranasactions error here
            dispatch({
              type: GET_TRANSACTIONS,
              payload: [],
            });
            console.log(
              "No transactions received from historical transaction api"
            );
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: GET_TRANSACTIONS,
            payload: [],
          });
        });
    }
  }
};

// Transactions loading
export const setTransactionsLoading = () => {
  return {
    type: TRANSACTIONS_LOADING,
  };
};

// This incomplete redux function is partially copied from the local state function
// in the OneMonthActivity component. It is unused because the logic is currently
// handled for the redux/30-day state in a combination of the authAction
// setTransactionSettings action and the processTransactionList helper
/*export const updateThirtyDayTransaction = (
  transaction_id,
  propName,
  propVal
) => (dispatch, getState) => {
  const state = getState();
  const incomeSum = state.plaid.incomeSum;
  const spendingSum = state.plaid.spendingSum;
  const copyAllTransactions = [...state.plaid.transactions];
  const copyGroupSumState = { ...state.plaid.categoryGroupSumObj };

  let foundTransaction = null;
  let foundIndex = -1;
  for (let a = 0; a < copyAllTransactions.length; a++) {
    foundTransaction = copyAllTransactions[a];
    if (foundTransaction.transaction_id === transaction_id) {
      foundIndex = a;
      a = copyAllTransactions.length;
    }
  }
  if (foundTransaction !== null) {
    const copyTransaction = { ...foundTransaction };
    const oldGroup = whichGroupForCategory(copyTransaction.category[0]);
    const oldGroupOldSum = copyGroupSumState[oldGroup];
    const amount = copyTransaction.amount;
    if (propName === "isDuplicate") {
      // In case we are toggling a transaction to be ignored
      if (propVal) {
        copyGroupSumState[oldGroup] = oldGroupOldSum - amount;
        // Amounts are positive if they are an expense
        if (amount < 0) {
          // Adding the negative amount to the income sum will reduce income sum
          incomeSum += amount;
        } else {
          spendingSum -= amount;
        }
      } else {
        // Otherwise, it was an ignored transaction that is now being counted
        copyGroupSumState[oldGroup] = oldGroupOldSum + amount;
        // Amounts are positive if they are an expense
        if (amount < 0) {
          // Subtracting the negative amount from the income sum will increase income sum
          incomeSum -= amount;
        } else {
          spendingSum += amount;
        }
      }

      copyTransaction.isDuplicate = propVal;
    }
    copyAllTransactions[foundIndex] = copyTransaction;

    return dispatch({
      type: SET_TRANSACTION_DATA,
      payload: {
        transactions: copyAllTransactions,
        categoryGroupSumObj: copyGroupSumState,
        incomeSum,
        spendingSum,
      },
    });
  }
};*/
