import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import ColorHeader from "../layout/ColorHeader.js";
import { setUserProfile } from "../../actions/authActions";

//import { Redirect } from "react-router-dom";

/*import { noDecimalCurrencyFormatter } from "../../utils/currencyFormatter";*/
//import percentFormatter from "../../utils/percentFormatter";
//import Loading from "../../utils/loading.js";
import { PlaidButton, DeleteAccountButton } from "../../utils/plaidButton.js";
import Loading from "../../utils/loading.js";
import LoggedInAsButton from "../layout/LoggedInAsButton.js";
import HistoricalTable from "./HistoricalTable.js";

// Note: I'm going to add a date picker dropdown in this component
// And I'll need a getHistory function
// In this component (once we have a current-month-dashboard) we will be viewing historical story comparing
// budget to expenses in that month
// I guess by default then it would show the story of the previous month? Or maybe default is the last 30 days?
const EasyHome = (props) => {
  /*const { plaid, auth } = props;
  const userName = auth.user.preferredName;*/

  // These variables used for shared login section (and following functions)
  // --------------------------------------------------------------------------
  const { /*user, */ getAccessTokenSilently } = useAuth0();
  const { authUser, setUserProfile } = props;
  const { currentOnBehalfUser } = props.auth;
  const [editName, setEditName] = useState(authUser.preferredName);
  const [showEditLoginList, setShowEditLoginList] = useState(false);
  const [showSharedLogin, setShowSharedLogin] = useState(true);
  // TODO: need to figure out why this is giving errors
  const [editLoginEmail, setEditLoginEmail] = useState(
    authUser.givePermToActAsMe[0].email
  );
  console.log("editname is " + editName);

  useEffect(() => {
    setEditLoginEmail(authUser.givePermToActAsMe[0].email);
  }, [authUser.givePermToActAsMe[0].email]); // Only re-run the effect if the value changes

  const onRemoveLoginEmail = () => {
    // Since we only allow a user the ability to grant loginAs to one user, the email to remove is that first item in list
    const emailToRemove = authUser.givePermToActAsMe[0].email;
    const sendObj = {
      ...authUser,
      givePermToActAsMe: [{ id: "_REMOVE_" }],
    };
    onSaveLoginEmail(emailToRemove, sendObj);
  };
  // Note that inputObj is an optional parameter in case a manual object is desired (like in the remove case)
  const onSaveLoginEmail = (newEmail, inputObj) => {
    if (currentOnBehalfUser._id) {
      return;
    }
    getAccessTokenSilently().then((accessToken) => {
      let trimmedNewEmail = "";
      if (newEmail) {
        trimmedNewEmail = newEmail.trim();
      }
      // Right now we show an error toast if they don't change/remove an existing email but they might not see it
      // Also it won't send to server unless the existing email was changed or the remove button was clicked
      if (
        trimmedNewEmail &&
        (trimmedNewEmail !== authUser.givePermToActAsMe[0].email ||
          (inputObj &&
            inputObj.givePermToActAsMe[0] &&
            inputObj.givePermToActAsMe[0].id === "_REMOVE_")) &&
        trimmedNewEmail.length < 50
      ) {
        let sendObj = {
          ...authUser,
          givePermToActAsMe: [{ email: trimmedNewEmail }],
        };
        // If there is an inputObj passed (from the removeLogin function for example) - use that instead of default
        if (inputObj) {
          sendObj = inputObj;
        }

        setUserProfile(accessToken, sendObj).then((result) => {
          if (result.response) {
            console.error("Error while trying to save loginAs email");
            setEditLoginEmail(authUser.givePermToActAsMe[0].email);
            toast.error("Could not change permission");
          } else {
            toast.success("Saved");
            // In this case we still need to hide the edit input
            if (
              inputObj &&
              inputObj.givePermToActAsMe[0] &&
              inputObj.givePermToActAsMe[0].id === "_REMOVE_"
            ) {
              setShowEditLoginList(false);
              setEditLoginEmail("");
            }
          }
        });
      } else {
        // If setting user data doesn't work, we will reset the local state instead of saving
        setEditLoginEmail(authUser.givePermToActAsMe[0].email);
        toast.error("Could not change permission");
      }
    });
  };
  const handleLoginEmailEdits = (event) => {
    setEditLoginEmail(event.target.value);
  };
  const onLoginEmailButtonClick = () => {
    if (showEditLoginList) {
      // User clicked save email
      onSaveLoginEmail(editLoginEmail);
    }
    setShowEditLoginList(!showEditLoginList);
  };
  const sharedLoginSection = (
    <>
      {/* {!currentOnBehalfUser._id &&
      (showSharedLogin ||
        Object.keys(authUser.appData.allowedToActAs).length ||
        authUser.givePermToActAsMe[0].email) ? (
        <>
          <label className="sectionHeader">
            Share with Hawaiian Community Assets
          </label>
          <span className="editname">
            {showEditLoginList ? (
              <input
                type="text"
                value={editLoginEmail}
                onChange={handleLoginEmailEdits}
                disabled={currentOnBehalfUser._id}
                placeHolder="counselor@hawaiiancommunity.net"
              ></input>
            ) : (
              editLoginEmail
            )}
          </span>
          <button
            className="small-button"
            onClick={onLoginEmailButtonClick}
            hidden={currentOnBehalfUser._id}
            disabled={currentOnBehalfUser._id}
          >
            {showEditLoginList
              ? "Save"
              : authUser.givePermToActAsMe[0].email
              ? "Edit"
              : "Add"}
          </button>
          <button
            className="small-button"
            onClick={onRemoveLoginEmail}
            hidden={
              currentOnBehalfUser._id ||
              !showEditLoginList ||
              !authUser.givePermToActAsMe[0].email ||
              (editLoginEmail &&
                editLoginEmail !== authUser.givePermToActAsMe[0].email)
            }
            disabled={
              currentOnBehalfUser._id ||
              !showEditLoginList ||
              !authUser.givePermToActAsMe[0].email ||
              (editLoginEmail &&
                editLoginEmail !== authUser.givePermToActAsMe[0].email)
            }
          >
            Remove
          </button>
          <br />
        </>
      ) : (
        <button
          className="small-button"
          onClick={() => {
            setShowSharedLogin(true);
          }}
          hidden={currentOnBehalfUser._id}
          disabled={currentOnBehalfUser._id}
        >
          Setup
        </button>
      )} */}
    </>
  );
  // --------------------------------------------------------------------------
  // These functions listAccounts and addButtonAndLink are for bank account UI

  // Note that in the EasyHome we are hiding the Cash account
  const listAccounts = props.plaid.accounts.map((account, i) => {
    if (account.institutionName === "Cash") {
      return <></>;
    }
    return (
      <div className="row mb-25" key={account._id}>
        <div className="col bankAccountRow">
          <p>
            <strong>{account.institutionName}</strong>
          </p>
          <br />
          {!props.auth.currentOnBehalfUser._id ? (
            <>
              <PlaidButton
                className="Secondary small"
                buttonText="Refresh"
                existingAccount={account}
              />{" "}
              <span className="notButton">
                <DeleteAccountButton account={account} />
              </span>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  });
  const addButtonAndLink = (
    <>
      <div className="row">
        <div className="col text-center">
          <PlaidButton
            buttonClass={props.plaid.accounts.length ? "" : "secondaryButton"}
            buttonText={
              !props.plaid.accounts.length
                ? "Connect your bank account"
                : "+ Bank account"
            }
            existingAccount={null}
          />
        </div>

        <div>
          {props.plaid.accounts.length && props.plaid.transactionsLoading ? (
            <Loading />
          ) : (
            <></>
          )}
          <></>
        </div>
      </div>
    </>
  );
  // --------------------------------------------------------------------------

  /*if (plaid.userFirstVisit) {
      return (
        <div>
          <Redirect to="/orientation" />
        </div>
      );
    } else if (!plaid.accounts || plaid.accounts.length === 0) {
      return (
        <div>
          <SpendRangeHeader
            mainHeaderText={userName}
            subHeaderText="Monthly Spend Story"
          />
          <Loading />
        </div>
      );
    }*/
  return (
    <>
      <ColorHeader
        mainHeaderText="Share Bank Info"
        subHeaderText="Connect your bank account in order to easily share financial information with Purple Mai'a."
        colorClassName="section-header-green"
      />
      <Container className="accessList">
        <LoggedInAsButton setProfileName={setEditName} hideButton={true} />
      </Container>

      <HistoricalTable shouldHideCategories={true} />

      <Container>
        <Row>
          <Col>
            <div className="bankAccounts">
              {!props.auth.currentOnBehalfUser._id ? addButtonAndLink : <></>}
              {!props.plaid.accountsLoading &&
              (props.plaid.accounts.length > 1 ||
                props.plaid.totalTransactionCount) ? (
                listAccounts
              ) : (
                <></>
              )}

              {props.auth.currentOnBehalfUser._id &&
              props.plaid.userFirstVisit ? (
                <span>
                  The shared account you are viewing has not added any bank
                  logins
                  {props.plaid.totalTransactionCount
                    ? " but has added cash transactions "
                    : " or cash transactions "}
                  to Waiwai
                </span>
              ) : (
                <></>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <div className="bankAccounts">{sharedLoginSection}</div>
      </Container>
    </>
  );
};

EasyHome.propTypes = {
  plaid: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  authUser: state.auth.user,
  plaid: state.plaid,
  auth: state.auth,
});
const mapDispatchToProps = {
  setUserProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(EasyHome);
