import React from "react";
import { connect } from "react-redux";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import moment from "moment";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { noDecimalCurrencyFormatter } from "../../../utils/currencyFormatter.js";

const ActivityTransactionsList = (props) => {
  const {
    allTypeDates,
    typeIDsByDate,
    transactionMap,
    allCategoriesArray,
    onCategorySelect,
    onDuplicateClick,
    currentOnBehalfUser,
  } = props;
  return (
    <>
      <h3 className="transactionsHeader">Transactions</h3>
      {allTypeDates ? (
        allTypeDates.map((typeDate) => {
          let typeIDList = typeIDsByDate[typeDate];
          return (
            <React.Fragment key={typeDate}>
              <Row className="date">
                <Col>{moment(typeDate).format("MMM D")}</Col>
              </Row>
              {typeIDList ? (
                typeIDList.map((transactionID) => {
                  let transaction = transactionMap[transactionID];
                  if (!transaction) {
                    return (
                      <React.Fragment key={transactionID}></React.Fragment>
                    );
                  }

                  return (
                    <div key={transactionID}>
                      <Row
                        className={
                          "TransactionRow " +
                          (transaction.isDuplicate ? "IgnoredTransaction" : "")
                        }
                        style={{
                          textDecoration: transaction.isDuplicate
                            ? "line-through"
                            : "auto",
                        }}
                      >
                        <Col xs={5}>
                          {transaction.merchant_name &&
                          transaction.merchant_name !== ""
                            ? transaction.merchant_name.substring(0, 20)
                            : transaction.name.substring(0, 20)}
                        </Col>
                        <Col xs={6} className="">
                          <span className="transactionNumber">
                            {noDecimalCurrencyFormatter.format(
                              transaction.amount * -1
                            )}
                          </span>
                          <DropdownButton
                            disabled={
                              currentOnBehalfUser._id || transaction.isDuplicate
                                ? true
                                : false
                            }
                            align="right"
                            title={transaction.category[0]}
                            className="notButton category dropdown-menu-right"
                            onSelect={(eventkey, event) =>
                              onCategorySelect(
                                eventkey,
                                event,
                                transaction.transaction_id
                              )
                            }
                          >
                            {allCategoriesArray.map((category) => {
                              return (
                                <Dropdown.Item
                                  key={category.name}
                                  eventKey={category.name}
                                >
                                  {category.name}
                                </Dropdown.Item>
                              );
                            })}
                          </DropdownButton>
                        </Col>
                        <Col xs={1}>
                          <DropdownButton
                            disabled={currentOnBehalfUser._id !== ""}
                            align="right"
                            title="..."
                            className="notButton menu"
                            onSelect={(eventkey, event) =>
                              onDuplicateClick({
                                id: transaction.transaction_id,
                                isDuplicate: transaction.isDuplicate,
                                categoryName: transaction.category[0],
                              })
                            }
                          >
                            <Dropdown.Item>
                              {transaction.isDuplicate
                                ? "Undo"
                                : "Mark Duplicate/Ignore"}
                            </Dropdown.Item>
                          </DropdownButton>
                        </Col>
                      </Row>
                    </div>
                  );
                })
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </React.Fragment>
          );
        })
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  allCategoriesArray: state.plaid.allCategoriesArray,
  currentOnBehalfUser: state.auth.currentOnBehalfUser,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityTransactionsList);
