import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../../utils/loading.js";
import ColorHeader from "../layout/ColorHeader.js";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { toast } from "react-toastify";
import { noDecimalCurrencyFormatter } from "../../utils/currencyFormatter";
import { saveUserBudget } from "../../actions/authActions";
// This component has the starter code for an income section with dropdowns
//import UnusedIncomeBudget from "./UnusedIncomeBudget.js";

// Note that currently we get a category from plaid called "Income"
// Transactions that have just the category of "Income" are counted towards other income
// In the manage transaction page, we show "Income" to the user as "Income - Other"
// And we give the option to the user to recategorize as "Income - Paycheck"

// NOTE - as of May 01 2021 - now the webclient just uses one "Income" category
//const payIncomeString = "Income - Paycheck";
//const payIncomeDisplay = "Paycheck Total";
//const otherIncomeString = "Income";
//const otherIncomeDisplay = "Other Income Total";

// NOTE - as of May 01 2021 - now the webclient just uses one "Income" category
// Putting this structure outside of the class so the spendrow props
// don't change on budget save
/*const incomeCategories = [
  { name: payIncomeString },
  { name: otherIncomeString },
];*/

// Note: I'm going to add a date picker dropdown in this component
// And I'll need a getHistory function
// But the function will only fetch/replace the amount-spent column
// So by default it shows last 30 days (or just previous month?)
// but user can pick another month to see the expense totals there

const SpendPlan = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [incomePlan, setIncomePlan] = useState(0);
  const [essentialsPlan, setEssentialsPlan] = useState(0);
  const [leaPlan, setLeaPlan] = useState(0);
  const [groupSaveActive, setGroupSaveActive] = useState({});
  const {
    incomeSum,
    categoryGroupSumObj,
    saveUserBudget,
    budgets,
    currentOnBehalfUser,
  } = props;

  useEffect(() => {
    if (budgets["Income"] !== undefined) {
      setIncomePlan(budgets["Income"]);
    }
    if (budgets["Essentials"] !== undefined) {
      setEssentialsPlan(budgets["Essentials"]);
    }
    if (budgets["Leʻa"] !== undefined) {
      setLeaPlan(budgets["Leʻa"]);
    }
  }, [budgets, setIncomePlan, setEssentialsPlan, setLeaPlan]);

  const clickSave = (groupName, newPlan, oldPlan, setter) => {
    const budgetData = { name: groupName, payload: {} };
    let trimmedBudgetString = "" + newPlan;

    if (newPlan !== undefined && newPlan.trim) {
      trimmedBudgetString = newPlan.trim();
    }
    // This checks if the string in budget is a number
    let valid = false;
    // This if will check if budget.trim is an empty string
    // Also since budget is a string, we don't have to worry about if (0) being false
    if (trimmedBudgetString) {
      // Then make sure the budget string is a number value
      valid = !isNaN(trimmedBudgetString);
    }
    if (valid) {
      toast.success("Saved");
      budgetData.payload[groupName] = parseFloat(trimmedBudgetString);
      // We only save the budget if it is a number
      // But should have UI here to tell the user something wrong happened if it isn't a number
      getAccessTokenSilently().then((accessToken) => {
        saveUserBudget(accessToken, budgetData);
      });
      // We can hide the save button because the save worked
      const copyObj = { ...groupSaveActive };
      copyObj[groupName] = false;
      setGroupSaveActive(copyObj);
    } else {
      // If the user clicked save but it wasn't a valid number, reset to redux state
      setter(oldPlan);
    }
  };

  const handlePlanChange = (setter, groupName) => (event) => {
    let valueToUse = 0;
    let trimmedInput = event.target.value.trim();
    if (trimmedInput) {
      valueToUse = parseFloat(trimmedInput);
    }
    setter(valueToUse);
    const copyObj = { ...groupSaveActive };
    copyObj[groupName] = true;
    setGroupSaveActive(copyObj);
  };

  if (props.accountsLoading || props.transactionsLoading) {
    return <Loading />;
  }
  return (
    <>
      <ColorHeader
        mainHeaderText="Monthly Plan"
        subHeaderText="Kilo. To kilo means to star gaze. It also means to watch closely, observe and examine. Kilo is an important practice for mahi ʻai, lawaiʻa, and navigators. Here you can kilo your expenses for the month, examine where to assign kuleana to ensure waiwai. "
        colorClassName="section-header-green"
      />
      <div className="section table-section spendPlan">
        <Container>
          <Row>
            <Col xs={6} lg={5}>
              <h2>Income</h2>
              <label className="normalLabel">
                What is your total monthly income?
              </label>
              <input
                type="number"
                value={incomePlan}
                onChange={handlePlanChange(setIncomePlan, "Income")}
                disabled={currentOnBehalfUser._id}
              />
              <p className="waiwaiEstimate blueText">
                Waiwai estimate:{" "}
                {incomeSum
                  ? noDecimalCurrencyFormatter.format(incomeSum)
                  : noDecimalCurrencyFormatter.format(0)}
              </p>
              <button
                className="btn save"
                disabled={currentOnBehalfUser._id}
                onClick={() =>
                  clickSave("Income", incomePlan, incomeSum, setIncomePlan)
                }
                style={{
                  visibility: groupSaveActive["Income"] ? "visible" : "hidden",
                }}
              >
                Save
              </button>
            </Col>
            <Col xs={6} lg={{ span: 4, offset: 1 }} className="contentBottom">
              <h3>Monthly Income Plan</h3>
              <p className="greenText largeNumber">
                +{noDecimalCurrencyFormatter.format(incomePlan)}
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={6} lg={5}>
              <h2>Spending</h2>
              <h3>Essential</h3>
              <label className="normalLabel">
                How much do you spend on what you need?{" "}
              </label>
              <p className="blueText small">
                Savings, Housing, transportation, groceries, utilities
              </p>
              <input
                type="number"
                value={essentialsPlan}
                onChange={handlePlanChange(setEssentialsPlan, "Essentials")}
                disabled={currentOnBehalfUser._id}
              />
              <p className="waiwaiEstimate blueText">
                Waiwai estimate:{" "}
                {categoryGroupSumObj["Essentials"]
                  ? noDecimalCurrencyFormatter.format(
                      categoryGroupSumObj["Essentials"]
                    )
                  : noDecimalCurrencyFormatter.format(0)}
              </p>
              <button
                className="btn save"
                disabled={currentOnBehalfUser._id}
                onClick={() =>
                  clickSave(
                    "Essentials",
                    essentialsPlan,
                    categoryGroupSumObj["Essentials"],
                    setEssentialsPlan
                  )
                }
                style={{
                  visibility: groupSaveActive["Essentials"]
                    ? "visible"
                    : "hidden",
                }}
              >
                Save
              </button>
              <h3>Leʻa</h3>
              <label className="normalLabel">
                How much are you spending on things you want?
              </label>
              <p className="blueText small">
                Shopping, Entertainment, Restaurants/Takeout, Gifts, Financial
              </p>
              <input
                type="number"
                value={leaPlan}
                onChange={handlePlanChange(setLeaPlan, "Leʻa")}
                disabled={currentOnBehalfUser._id}
              />
              <p className="waiwaiEstimate blueText">
                Waiwai estimate:{" "}
                {categoryGroupSumObj["Leʻa"]
                  ? noDecimalCurrencyFormatter.format(
                      categoryGroupSumObj["Leʻa"]
                    )
                  : noDecimalCurrencyFormatter.format(0)}
              </p>
              <button
                className="btn save"
                disabled={currentOnBehalfUser._id}
                onClick={() =>
                  clickSave(
                    "Leʻa",
                    leaPlan,
                    categoryGroupSumObj["Leʻa"],
                    setLeaPlan
                  )
                }
                style={{
                  visibility: groupSaveActive["Leʻa"] ? "visible" : "hidden",
                }}
              >
                Save
              </button>
            </Col>
            <Col xs={6} lg={{ span: 4, offset: 1 }} className="contentBottom">
              <h3>Monthly Spend Plan</h3>
              <p className="blueText  largeNumber">
                -{noDecimalCurrencyFormatter.format(essentialsPlan + leaPlan)}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Planned Monthly</h3>
              <p className="greenText largestNumber">
                {noDecimalCurrencyFormatter.format(
                  incomePlan - essentialsPlan - leaPlan
                )}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      {/*<div className="section">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2>Income</h2>
              <Table className="planned-tables" hover>
                <thead>
                  <tr>
                    <th></th>

                    <th>{props.currentMonthName} Planned Income</th>
                    <th>Earned last 30 days</th>
                  </tr>
                </thead>
                <tbody>
                  {incomeCategories.map((category) => {
                    return (
                      <SpendPlanRow
                        key={category.name}
                        category={category}
                        propBudget={budgets[category.name]}
                      ></SpendPlanRow>
                    );
                  })}
                  <PlanTotalRow rowDisplayName="Income" />
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
      <div className="section table-section">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2>Expenses</h2>
              <Table className="planned-tables" hover>
                <thead>
                  <tr>
                    <th></th>

                    <th>{props.currentMonthName} Planned Expenses</th>
                    <th>Spent last 30 days</th>
                  </tr>
                </thead>
                <tbody>
                  {props.categoriesThisSpendRange.map((category, i) => {
                    if (category.name.includes("Income")) {
                      return (
                        <React.Fragment key={category.name}></React.Fragment>
                      );
                    }
                    return (
                      <SpendPlanRow
                        key={category.name}
                        category={category}
                        propBudget={budgets[category.name]}
                      ></SpendPlanRow>
                    );
                  })}
                  <PlanTotalRow rowDisplayName="Expense" />
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>*/}
    </>
  );
};

const mapStateToProps = (state) => ({
  incomeSum: state.plaid.incomeSum,
  budgets: state.auth.budgets,
  categoryGroupSumObj: state.plaid.categoryGroupSumObj,
  accountsLoading: state.plaid.accountsLoading,
  transactionsLoading: state.plaid.transactionsLoading,
  currentOnBehalfUser: state.auth.currentOnBehalfUser,
});
const mapDispatchToProps = { saveUserBudget };

// Note that there is probably a better way to do this with React hooks now
export default connect(mapStateToProps, mapDispatchToProps)(SpendPlan);
