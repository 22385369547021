export const AUTH_MOCK_DATA = {
  user: {
    preferredName: "Test Person",
  },
  loading: false,
  budgets: {
    Food: 200,
    Debt: 50,
    Transportation: 20,
    Entertainment: 10,
    Personal: 100,
  },
  expenseBudgetSum: 380,
  spendRangeDays: 30,
  perTransactionSettings: {},
};
// NOTE THAT I'VE ADDED EXTRA MOCK DATA FOR
// categoriesThisSpendRange, spendingByCategory, incomeSum, and spendingSum
// (because it could be a different time period)
export const PLAID_MOCK_DATA = {
  accounts: [
    {
      _id: "5f49c1e2a80e4610fd0bf121",
      userId: "auth0|5f49a0239c5106006de188a7",
      itemId: "gV6ZBM1kanFa3LjGkG5JIvN54wjW8WuEBEkyQ",
      institutionId: "ins_6",
      institutionName: "US Bank",
      __v: 0,
    },
    {
      _id: "5f4b054ece06fa012ce5ef3b",
      toRefresh: true,
      publicToken: "",
      userId: "auth0|5f49a0239c5106006de188a7",
      itemId: "r3rqnGXrwDCJzn4N8l87UgwZmoWvjlt7ev9eb",
      institutionId: "ins_13",
      institutionName: "PNC",
      __v: 0,
    },
  ],
  transactions: [
    {
      accountName: "US Bank",
      transactions: [
        {
          account_id: "E4WEAVZvBmFKleBJnJDrs1DWAowWM6cX1QKG9",
          account_owner: null,
          amount: 500,
          authorized_date: null,
          category: ["Transportation", "Airlines and Aviation Services"],
          category_id: "22001000",
          date: "2020-08-28",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: "United Airlines",
          name: "United Airlines",
          payment_channel: "in store",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "eVDQrMZ1xAFyeRNnqnBmh4pyqkqgamtL9vxye",
          transaction_type: "special",
          unofficial_currency_code: null,
          plaid_categories: ["Travel", "Airlines and Aviation Services"],
          waiwai_categories: [
            "Transportation",
            "Airlines and Aviation Services",
          ],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "oVoNj1A9mqFozvpLwLqWI8m6xzr67auRz9lgR",
          account_owner: null,
          amount: 6.33,
          authorized_date: null,
          category: ["Transportation", "Taxi"],
          category_id: "22016000",
          date: "2020-08-26",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: "Uber",
          name: "Uber 072515 SF**POOL**",
          payment_channel: "in store",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "3gxwanmdXjHmgK5GrGkQTwqXQ6Q1K5CqlgWGq",
          transaction_type: "special",
          unofficial_currency_code: null,
          plaid_categories: ["Travel", "Taxi"],
          waiwai_categories: ["Transportation", "Taxi"],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "E4WEAVZvBmFKleBJnJDrs1DWAowWM6cX1QKG9",
          account_owner: null,
          amount: 500,
          authorized_date: null,
          category: ["Food", "Restaurants"],
          category_id: "13005000",
          date: "2020-08-23",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: "Tectra Inc",
          name: "Tectra Inc",
          payment_channel: "in store",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "QoAgNpnmGxTdK4BgDgLpU58qDrDMnVtpmPjrA",
          transaction_type: "place",
          unofficial_currency_code: null,
          plaid_categories: ["Food and Drink", "Restaurants"],
          waiwai_categories: ["Food", "Restaurants"],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "E4WEAVZvBmFKleBJnJDrs1DWAowWM6cX1QKG9",
          account_owner: null,
          amount: 2078.5,
          authorized_date: null,
          category: ["Debt", ""],
          category_id: "16000000",
          date: "2020-08-22",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: null,
          name: "AUTOMATIC PAYMENT - THANK",
          payment_channel: "other",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "1pB3x6nmMNudGy5l8lRAUQkbgvgJGxH5j9DbA",
          transaction_type: "special",
          unofficial_currency_code: null,
          plaid_categories: ["Payment"],
          waiwai_categories: ["Debt", ""],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "E4WEAVZvBmFKleBJnJDrs1DWAowWM6cX1QKG9",
          account_owner: null,
          amount: 500,
          authorized_date: null,
          category: ["Food", "Restaurants"],
          category_id: "13005032",
          date: "2020-08-22",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: "KFC",
          name: "KFC",
          payment_channel: "in store",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "MMVGxgkoNru6EvVkBkARi1dP8V8lRzI96Xmbj",
          transaction_type: "place",
          unofficial_currency_code: null,
          plaid_categories: ["Food and Drink", "Restaurants", "Fast Food"],
          waiwai_categories: ["Food", "Restaurants"],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "E4WEAVZvBmFKleBJnJDrs1DWAowWM6cX1QKG9",
          account_owner: null,
          amount: 500,
          authorized_date: null,
          category: ["Personal", "Sporting Goods"],
          category_id: "19046000",
          date: "2020-08-22",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: "Madison Bicycle Shop",
          name: "Madison Bicycle Shop",
          payment_channel: "in store",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "ZVADLE3eyQF18DlENEdqIBv4pPp7AaFgqaxnA",
          transaction_type: "place",
          unofficial_currency_code: null,
          plaid_categories: ["Shops", "Sporting Goods"],
          waiwai_categories: ["Personal", "Sporting Goods"],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "gV6ZBM1kanFa3LjGkG5JIvNn75bnKDcgwDmzE",
          account_owner: null,
          amount: 25,
          authorized_date: null,
          category: ["Debt", "Credit Card"],
          category_id: "16001000",
          date: "2020-08-13",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: null,
          name: "CREDIT CARD 3333 PAYMENT *//",
          payment_channel: "other",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "PpAX4kGoZ3uJ4K6eweD7I98A5K5kwZi76LKE8",
          transaction_type: "special",
          unofficial_currency_code: null,
          plaid_categories: ["Payment", "Credit Card"],
          waiwai_categories: ["Debt", "Credit Card"],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "oVoNj1A9mqFozvpLwLqWI8m6xzr67auRz9lgR",
          account_owner: null,
          amount: 5.4,
          authorized_date: null,
          category: ["Transportation", "Taxi"],
          category_id: "22016000",
          date: "2020-08-13",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: "Uber",
          name: "Uber 063015 SF**POOL**",
          payment_channel: "in store",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "xVmwoDz5W4Fjrabxvx4EFeN9WyW4jwin4MQm6",
          transaction_type: "special",
          unofficial_currency_code: null,
          plaid_categories: ["Travel", "Taxi"],
          waiwai_categories: ["Transportation", "Taxi"],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "WGAgZXrzyjUGg4ZJEJowH7vGj8dGQ6ClDMmn4",
          account_owner: null,
          amount: 5850,
          authorized_date: null,
          category: ["Personal", "Debit"],
          category_id: "21006000",
          date: "2020-08-12",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: null,
          name: "ACH Electronic CreditGUSTO PAY 123456",
          payment_channel: "other",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: "ACH",
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "pVmp7MG9RKFe7yobdbKaslRPL8LMz6tLolX9R",
          transaction_type: "special",
          unofficial_currency_code: null,
          plaid_categories: ["Transfer", "Debit"],
          waiwai_categories: ["Personal", "Debit"],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "8GPV7JzqjMURp67ZeZKzc9PnVzen3QswGalMZ",
          account_owner: null,
          amount: 1000,
          authorized_date: null,
          category: ["Income", "Deposit"],
          category_id: "21007000",
          date: "2020-08-12",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: null,
          name: "CD DEPOSIT .INITIAL.",
          payment_channel: "other",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "7AN3jRrl5ZhZ3LK5B5krIJng848lpWugJbw43",
          transaction_type: "special",
          unofficial_currency_code: null,
          plaid_categories: ["Transfer", "Deposit"],
          waiwai_categories: ["Income", "Deposit"],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "E4WEAVZvBmFKleBJnJDrs1DWAowWM6cX1QKG9",
          account_owner: null,
          amount: 78.5,
          authorized_date: null,
          category: ["Entertainment", "Gyms and Fitness Centers"],
          category_id: "17018000",
          date: "2020-08-11",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: "Touchstone",
          name: "Touchstone Climbing",
          payment_channel: "in store",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "LZwy3p8oNKCDJWxZqZkoIGnwgZgQM1HPj63gZ",
          transaction_type: "place",
          unofficial_currency_code: null,
          plaid_categories: ["Recreation", "Gyms and Fitness Centers"],
          waiwai_categories: ["Entertainment", "Gyms and Fitness Centers"],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "oVoNj1A9mqFozvpLwLqWI8m6xzr67auRz9lgR",
          account_owner: null,
          amount: -500,
          authorized_date: null,
          category: ["Transportation", "Airlines and Aviation Services"],
          category_id: "22001000",
          date: "2020-08-11",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: "United Airlines",
          name: "United Airlines",
          payment_channel: "other",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "dV9aKMQ83WF8AX4EwEzRFjv8wqwQeLfZEyXoB",
          transaction_type: "special",
          unofficial_currency_code: null,
          plaid_categories: ["Travel", "Airlines and Aviation Services"],
          waiwai_categories: [
            "Transportation",
            "Airlines and Aviation Services",
          ],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "oVoNj1A9mqFozvpLwLqWI8m6xzr67auRz9lgR",
          account_owner: null,
          amount: 12,
          authorized_date: null,
          category: ["Food", "Restaurants"],
          category_id: "13005032",
          date: "2020-08-10",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: "3322",
            zip: null,
          },
          merchant_name: "McDonald's",
          name: "McDonald's",
          payment_channel: "in store",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "aVGyA6j9rgFeXrxlJlq7sBpeJPJMEzF756Xp1",
          transaction_type: "place",
          unofficial_currency_code: null,
          plaid_categories: ["Food and Drink", "Restaurants", "Fast Food"],
          waiwai_categories: ["Food", "Restaurants"],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "oVoNj1A9mqFozvpLwLqWI8m6xzr67auRz9lgR",
          account_owner: null,
          amount: 4.33,
          authorized_date: null,
          category: ["Food", "Restaurants"],
          category_id: "13005043",
          date: "2020-08-10",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: "Starbucks",
          name: "Starbucks",
          payment_channel: "in store",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "48dj4nlGk7uxV31GQGRligAZWKW53eHdbpm83",
          transaction_type: "place",
          unofficial_currency_code: null,
          plaid_categories: ["Food and Drink", "Restaurants", "Coffee Shop"],
          waiwai_categories: ["Food", "Restaurants"],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "oVoNj1A9mqFozvpLwLqWI8m6xzr67auRz9lgR",
          account_owner: null,
          amount: 89.4,
          authorized_date: null,
          category: ["Food", "Restaurants"],
          category_id: "13005000",
          date: "2020-08-09",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: "Sparkfun",
          name: "SparkFun",
          payment_channel: "in store",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "N1ybd8WgjzSKQ19nanDqsqv85n5jx3UW68VLx",
          transaction_type: "place",
          unofficial_currency_code: null,
          plaid_categories: ["Food and Drink", "Restaurants"],
          waiwai_categories: ["Food", "Restaurants"],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "gV6ZBM1kanFa3LjGkG5JIvNn75bnKDcgwDmzE",
          account_owner: null,
          amount: -4.22,
          authorized_date: null,
          category: ["Income", "Credit"],
          category_id: "21005000",
          date: "2020-08-08",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: null,
          name: "INTRST PYMNT",
          payment_channel: "other",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "jV6XdLoPmyF5EaQdmd4XsV7lmNmrb9C1WKQoQ",
          transaction_type: "special",
          unofficial_currency_code: null,
          plaid_categories: ["Transfer", "Credit"],
          waiwai_categories: ["Income", "Credit"],
          isReviewed: false,
          isDuplicate: false,
        },
      ],
    },
    {
      accountName: "PNC",
      transactions: [
        {
          account_id: "llb7nGgbADhrPeQAMzM9C1k8DPRvmrCZVRK9q",
          account_owner: null,
          amount: 500,
          authorized_date: null,
          category: ["Transportation", "Airlines and Aviation Services"],
          category_id: "22001000",
          date: "2020-08-28",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: "United Airlines",
          name: "United Airlines",
          payment_channel: "in store",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "r3rqnGXrwDCJzn4N8l87UgwRpqJNNgHlnm4Wv",
          transaction_type: "special",
          unofficial_currency_code: null,
          plaid_categories: ["Travel", "Airlines and Aviation Services"],
          waiwai_categories: [
            "Transportation",
            "Airlines and Aviation Services",
          ],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "5rd7mBzd5bU9v35bBaBkIBP1Mv8oQmcZ18aBP",
          account_owner: null,
          amount: 6.33,
          authorized_date: null,
          category: ["Transportation", "Taxi"],
          category_id: "22016000",
          date: "2020-08-26",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: "Uber",
          name: "Uber 072515 SF**POOL**",
          payment_channel: "in store",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "DMgoDLKgBqTALj3mo8oPHlawEBDAAlFvkdDla",
          transaction_type: "special",
          unofficial_currency_code: null,
          plaid_categories: ["Travel", "Taxi"],
          waiwai_categories: ["Transportation", "Taxi"],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "llb7nGgbADhrPeQAMzM9C1k8DPRvmrCZVRK9q",
          account_owner: null,
          amount: 500,
          authorized_date: null,
          category: ["Food", "Restaurants"],
          category_id: "13005000",
          date: "2020-08-23",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: "Tectra Inc",
          name: "Tectra Inc",
          payment_channel: "in store",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "z7NJXGBNzrtlPwvpBRBXTnVGLol33nfozjrZv",
          transaction_type: "place",
          unofficial_currency_code: null,
          plaid_categories: ["Food and Drink", "Restaurants"],
          waiwai_categories: ["Food", "Restaurants"],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "llb7nGgbADhrPeQAMzM9C1k8DPRvmrCZVRK9q",
          account_owner: null,
          amount: 2078.5,
          authorized_date: null,
          category: ["Debt", ""],
          category_id: "16000000",
          date: "2020-08-22",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: null,
          name: "AUTOMATIC PAYMENT - THANK",
          payment_channel: "other",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "xPaxnGLaKqUW4Rlpb3bmsDy4NQWrrDfnqgjXz",
          transaction_type: "special",
          unofficial_currency_code: null,
          plaid_categories: ["Payment"],
          waiwai_categories: ["Debt", ""],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "llb7nGgbADhrPeQAMzM9C1k8DPRvmrCZVRK9q",
          account_owner: null,
          amount: 500,
          authorized_date: null,
          category: ["Food", "Restaurants"],
          category_id: "13005032",
          date: "2020-08-22",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: "KFC",
          name: "KFC",
          payment_channel: "in store",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "3q3PArV3JbUbkePJ5y58FE61qdQNNESqBX6vA",
          transaction_type: "place",
          unofficial_currency_code: null,
          plaid_categories: ["Food and Drink", "Restaurants", "Fast Food"],
          waiwai_categories: ["Food", "Restaurants"],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "llb7nGgbADhrPeQAMzM9C1k8DPRvmrCZVRK9q",
          account_owner: null,
          amount: 500,
          authorized_date: null,
          category: ["Personal", "Sporting Goods"],
          category_id: "19046000",
          date: "2020-08-22",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: "Madison Bicycle Shop",
          name: "Madison Bicycle Shop",
          payment_channel: "in store",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "BQpwbMNpkZf8PNJx9l94UM98mwLZZMiwQaERP",
          transaction_type: "place",
          unofficial_currency_code: null,
          plaid_categories: ["Shops", "Sporting Goods"],
          waiwai_categories: ["Personal", "Sporting Goods"],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "J8nkDqdnVxTbozEe7V7MF37P6mGDLBTdDkl7E",
          account_owner: null,
          amount: 25,
          authorized_date: null,
          category: ["Debt", "Credit Card"],
          category_id: "16001000",
          date: "2020-08-13",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: null,
          name: "CREDIT CARD 3333 PAYMENT *//",
          payment_channel: "other",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "llb7nGgbADhrPeQAMzM9C1k3PrmKK1hZkNQzo",
          transaction_type: "special",
          unofficial_currency_code: null,
          plaid_categories: ["Payment", "Credit Card"],
          waiwai_categories: ["Debt", "Credit Card"],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "5rd7mBzd5bU9v35bBaBkIBP1Mv8oQmcZ18aBP",
          account_owner: null,
          amount: 5.4,
          authorized_date: null,
          category: ["Transportation", "Taxi"],
          category_id: "22016000",
          date: "2020-08-13",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: "Uber",
          name: "Uber 063015 SF**POOL**",
          payment_channel: "in store",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "VxdpDjodqLhZWb7d3K3niMwmPb8ZZMiWwl15d",
          transaction_type: "special",
          unofficial_currency_code: null,
          plaid_categories: ["Travel", "Taxi"],
          waiwai_categories: ["Transportation", "Taxi"],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "qBKlyG5KADUprV8agQgNcBlnGDrmpqcdzRebm",
          account_owner: null,
          amount: 5850,
          authorized_date: null,
          category: ["Personal", "Debit"],
          category_id: "21006000",
          date: "2020-08-12",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: null,
          name: "ACH Electronic CreditGUSTO PAY 123456",
          payment_channel: "other",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: "ACH",
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "aM1kb8J17XTDq8Z3xyx5iWPMpbJwwWU7znwb4",
          transaction_type: "special",
          unofficial_currency_code: null,
          plaid_categories: ["Transfer", "Debit"],
          waiwai_categories: ["Personal", "Debit"],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "kGnwW6XnADunN8wQVrVes4rN1WPeAbFWlo4P9",
          account_owner: null,
          amount: 1000,
          authorized_date: null,
          category: ["Income", "Deposit"],
          category_id: "21007000",
          date: "2020-08-12",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: null,
          name: "CD DEPOSIT .INITIAL.",
          payment_channel: "other",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "KEbRDKwbPdIwDleQrPr1hxrZLW5wwxfVLJq7x",
          transaction_type: "special",
          unofficial_currency_code: null,
          plaid_categories: ["Transfer", "Deposit"],
          waiwai_categories: ["Income", "Deposit"],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "llb7nGgbADhrPeQAMzM9C1k8DPRvmrCZVRK9q",
          account_owner: null,
          amount: 78.5,
          authorized_date: null,
          category: ["Entertainment", "Gyms and Fitness Centers"],
          category_id: "17018000",
          date: "2020-08-11",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: "Touchstone",
          name: "Touchstone Climbing",
          payment_channel: "in store",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "dpog6keoZXTPzJda4D4vSPqQvawJJPtZm9KdM",
          transaction_type: "place",
          unofficial_currency_code: null,
          plaid_categories: ["Recreation", "Gyms and Fitness Centers"],
          waiwai_categories: ["Entertainment", "Gyms and Fitness Centers"],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "5rd7mBzd5bU9v35bBaBkIBP1Mv8oQmcZ18aBP",
          account_owner: null,
          amount: -500,
          authorized_date: null,
          category: ["Transportation", "Airlines and Aviation Services"],
          category_id: "22001000",
          date: "2020-08-11",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: "United Airlines",
          name: "United Airlines",
          payment_channel: "other",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "wNd3mGDdqlS3aKwEnWnQInG5M63rrnfr5Mwym",
          transaction_type: "special",
          unofficial_currency_code: null,
          plaid_categories: ["Travel", "Airlines and Aviation Services"],
          waiwai_categories: [
            "Transportation",
            "Airlines and Aviation Services",
          ],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "5rd7mBzd5bU9v35bBaBkIBP1Mv8oQmcZ18aBP",
          account_owner: null,
          amount: 12,
          authorized_date: null,
          category: ["Food", "Restaurants"],
          category_id: "13005032",
          date: "2020-08-10",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: "3322",
            zip: null,
          },
          merchant_name: "McDonald's",
          name: "McDonald's",
          payment_channel: "in store",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "5rd7mBzd5bU9v35bBaBkIBPkvmQllBuZWJl4Q",
          transaction_type: "place",
          unofficial_currency_code: null,
          plaid_categories: ["Food and Drink", "Restaurants", "Fast Food"],
          waiwai_categories: ["Food", "Restaurants"],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "5rd7mBzd5bU9v35bBaBkIBP1Mv8oQmcZ18aBP",
          account_owner: null,
          amount: 4.33,
          authorized_date: null,
          category: ["Food", "Restaurants"],
          category_id: "13005043",
          date: "2020-08-10",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: "Starbucks",
          name: "Starbucks",
          payment_channel: "in store",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "J8nkDqdnVxTbozEe7V7MF374mBLbb3sd9x3KX",
          transaction_type: "place",
          unofficial_currency_code: null,
          plaid_categories: ["Food and Drink", "Restaurants", "Coffee Shop"],
          waiwai_categories: ["Food", "Restaurants"],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "5rd7mBzd5bU9v35bBaBkIBP1Mv8oQmcZ18aBP",
          account_owner: null,
          amount: 89.4,
          authorized_date: null,
          category: ["Food", "Restaurants"],
          category_id: "13005000",
          date: "2020-08-09",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: "Sparkfun",
          name: "SparkFun",
          payment_channel: "in store",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "kGnwW6XnADunN8wQVrVes4r5WbAoo4FWXanGD",
          transaction_type: "place",
          unofficial_currency_code: null,
          plaid_categories: ["Food and Drink", "Restaurants"],
          waiwai_categories: ["Food", "Restaurants"],
          isReviewed: false,
          isDuplicate: false,
        },
        {
          account_id: "J8nkDqdnVxTbozEe7V7MF37P6mGDLBTdDkl7E",
          account_owner: null,
          amount: -4.22,
          authorized_date: null,
          category: ["Income", "Credit"],
          category_id: "21005000",
          date: "2020-08-08",
          iso_currency_code: "USD",
          location: {
            address: null,
            city: null,
            lat: null,
            lon: null,
            state: null,
            store_number: null,
            zip: null,
          },
          merchant_name: null,
          name: "INTRST PYMNT",
          payment_channel: "other",
          payment_meta: {
            by_order_of: null,
            payee: null,
            payer: null,
            payment_method: null,
            payment_processor: null,
            ppd_id: null,
            reason: null,
            reference_number: null,
          },
          pending: false,
          pending_transaction_id: null,
          transaction_id: "qBKlyG5KADUprV8agQgNcBlKDqpwwBudZxGvK",
          transaction_type: "special",
          unofficial_currency_code: null,
          plaid_categories: ["Transfer", "Credit"],
          waiwai_categories: ["Income", "Credit"],
          isReviewed: false,
          isDuplicate: false,
        },
      ],
    },
  ],
  accountsLoading: false,
  transactionsLoading: false,
  userFirstVisit: false,
  incomeSum: 1008.44,
  paycheckSum: 0,
  otherIncomeSum: 1008.44,
  spendingSum: 22298.920000000002,
  totalTransactionCount: 32,
  reviewedTransactionCount: 0,
  needPlaidRefresh: true,
  categoriesThisSpendRange: [
    {
      x: 1,
      name: "Housing",
    },
    {
      x: 2,
      name: "Transportation",
    },
    {
      x: 3,
      name: "Food",
    },
    {
      x: 4,
      name: "Utilities",
    },
    {
      x: 5,
      name: "Insurance & Healthcare",
    },
    {
      x: 6,
      name: "Debt",
    },
    {
      x: 7,
      name: "Personal",
    },
    {
      x: 8,
      name: "Education",
    },
    {
      x: 9,
      name: "Child Care",
    },
    {
      x: 10,
      name: "Entertainment",
    },
    {
      x: 11,
      name: "Savings",
    },
    {
      x: 12,
      name: "Income",
    },
  ],
  spendingByCategory: {
    Housing: 0,
    Transportation: 1023.46,
    Food: 2211.46,
    Utilities: 0,
    "Insurance & Healthcare": 0,
    Debt: 4207,
    Personal: 12700,
    Education: 0,
    "Child Care": 0,
    Entertainment: 157,
    Savings: 0,
    Income: 2000,
  },
  /*sortedCategoriesUnderBudget: [
    "Housing",
    "Utilities",
    "Insurance & Healthcare",
    "Education",
    "Child Care",
  ],
  sortedCategoriesOverBudget: [
    "Transportation",
    "Food",
    "Debt",
    "Entertainment",
    "Personal",
    "Savings",
    "Income",
  ],*/
};
