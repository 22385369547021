import React from "react";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
//import { Link } from "react-router-dom";
import moment from "moment";
import Loading from "../../../utils/loading.js";
import ColorHeader from "../../layout/ColorHeader.js";
import OneMonthActivity from "./OneMonthActivity.js";

const AllMonthlyActivity = (props) => {
  const { isAuthenticated } = useAuth0();
  const { accounts, allCategoriesArray } = props;
  const startMonth = moment().startOf("month");
  const prevMonth = moment(startMonth).subtract(1, "month");
  //const prePrevMonth = moment(startMonth).subtract(2, "month");
  return (
    <>
      <ColorHeader
        mainHeaderText="Activity"
        subHeaderText="Mālama. Huli ka lima i lalo. Like mahi ʻai who tended the loʻi, removing weeds, checking ʻauwai to ensure the wai was moving, here is where you can review and categorize your transactions to mālama waiwai."
        colorClassName="section-header-green"
      />
      <div className="section table-section">
        <div className="container">
          <div className="scroll-wrapper">
            {!isAuthenticated ? (
              <Loading />
            ) : (
              <>
                {/* We are commenting this out now so that monthly-based transaction is the only option */}
                {/*<Link to="/all-recent-transactions">
                  See last 30 days in one table
                </Link>*/}
                <OneMonthActivity
                  whichMonth={moment(startMonth)}
                  accounts={accounts}
                  allCategoriesArray={allCategoriesArray}
                />
                <OneMonthActivity
                  whichMonth={moment(prevMonth)}
                  accounts={accounts}
                  allCategoriesArray={allCategoriesArray}
                />

                {/*<OneMonthActivity whichMonth={moment(prePrevMonth)} accounts={accounts} />*/}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  // Note that the sub-components (onemonth ones) won't render data without something in this map
  accounts: state.plaid.accounts,
  allCategoriesArray: state.plaid.allCategoriesArray,
});

// Note that there is probably a better way to do this with React hooks now
export default connect(mapStateToProps, {}, null, { pure: true })(
  AllMonthlyActivity
);
