import axios from "axios";
import setAxiosAuth from "../utils/setAxiosAuth.js";
import { whichGroupForCategory } from "../utils/waiwaiCategories.js";

// We only need moment if we are going to filter transactions frontend-side
//import moment from "moment";

import {
  GET_ERRORS,
  SET_CURRENT_USER,
  USER_LOADING,
  SET_BUDGETS,
  GET_USER_INFO,
  TRANSACTIONS_LOADING,
  GET_TRANSACTIONS,
  SET_SPEND_RANGE_DAYS_SELECTED,
  SET_CURRENT_ON_BEHALF_USER,
  USER_FIRST_VISIT,
} from "./types";
import { processTransactionList } from "../utils/processTransactionList.js";
import { getTransactions } from "./accountActions.js";

export const saveUserBudget = (accessToken, budgetData) => (
  dispatch,
  getState
) => {
  const state = getState();
  if (accessToken) {
    setAxiosAuth(accessToken);
  }
  //const { categoriesThisSpendRange, spendingByCategory } = state.plaid;
  const { expenseBudgetSum } = state.auth;
  let oldBudgetAmount = 0;
  if (state.auth.budgets[budgetData.name]) {
    oldBudgetAmount = state.auth.budgets[budgetData.name];
  }
  const newBudgetAmount = budgetData.payload[budgetData.name];
  const allBudgets = { ...state.auth.budgets, ...budgetData.payload };

  const isIncomeBudgetChange = budgetData.name.includes("Income");
  // The default is to adjust the budget sum based on the new amount coming in
  let newBudgetSum = expenseBudgetSum - oldBudgetAmount + newBudgetAmount;
  // But if this is an income budget adjustment, then just use the old expense sum
  if (isIncomeBudgetChange) {
    newBudgetSum = expenseBudgetSum;
  }

  const budgetPayload = {
    allBudgets,
    expenseBudgetSum: newBudgetSum,
  };
  dispatch(setCurrentBudgets(budgetPayload));

  // Only need to update sorted categories for over/under budget for expense budget changes
  // Commenting out the sortedCategory logic because we donʻt do this anymore
  /*if (!isIncomeBudgetChange) {
    const sortedCategories = updateSortedCategories(
      categoriesThisSpendRange,
      spendingByCategory,
      allBudgets
    );
    const updateTransactions = {
      sortedCategoriesOverBudget: sortedCategories.overBudgets,
      sortedCategoriesUnderBudget: sortedCategories.underBudgets,
    };
    dispatch({
      type: SET_TRANSACTION_DATA,
      payload: updateTransactions,
    });
  }*/
  // Note: if we leave this api call in this position for now, the demo for frontend-only
  // will continue to work but the downside is that if there is a
  // server error then the frontend and backend could get out of sync.
  // So that is why the axios.then statement just returns (since we handle frontend updates before)

  axios
    .post(`/api/users/budgets`, {
      budgetName: budgetData.name,
      budgetAmount: newBudgetAmount,
      expenseBudgetSum: newBudgetSum,
    })
    .then((res) => {
      //dispatch(setCookiesAndCurrentBudgets(res.data.userId, res.data.budgets));
      return res;
    })
    .catch((err) => {
      let toSend = err;
      if (err.response) {
        toSend = err.response.data;
      }
      dispatch({
        type: GET_ERRORS,
        payload: toSend,
      });
    });
};

export const setTransactionSettings = (accessToken, transactionData) => (
  dispatch,
  getState
) => {
  const state = getState();

  const transactionID = transactionData.transactionID;

  const newCategoryName = transactionData.newMainCategory;
  const newGroup = transactionData.newGroup;
  const newReviewedState = transactionData.newReviewedState;
  const newDuplicateState = transactionData.newDuplicateState;
  const isNewCategory = newCategoryName !== undefined;
  const isNewReviewed = newReviewedState !== undefined;
  const isNewDuplicate = newDuplicateState !== undefined;

  // NOTE, don't think I have to do this if just use return of object from server
  // for the dispatch payload - however as mentioned in budgetData, doing it this
  // way lets frontend-only deployment continue to work
  // ------------------------------------------------------------------------
  const { perTransactionSettings } = state.auth;
  let payloadArray = [];
  let newTransactionSettings = { ...perTransactionSettings };
  if (perTransactionSettings && perTransactionSettings[transactionID]) {
    newTransactionSettings[transactionID] = {
      ...perTransactionSettings[transactionID],
    };
    if (perTransactionSettings[transactionID].userCategories && isNewCategory) {
      payloadArray = [...perTransactionSettings[transactionID].userCategories];
    }
  } else {
    newTransactionSettings[transactionID] = {};
  }
  if (isNewCategory) {
    payloadArray[0] = newCategoryName;
    payloadArray[1] = newGroup;
    newTransactionSettings[transactionID].userCategories = payloadArray;
  }
  if (isNewReviewed) {
    newTransactionSettings[transactionID].isReviewed = newReviewedState;
  }
  if (isNewDuplicate) {
    newTransactionSettings[transactionID].isDuplicate = newDuplicateState;
  }

  // ------------------------------------------------------------------------
  dispatch({
    type: GET_USER_INFO,
    payload: { newTransactionSettings },
  });

  // Send to server and use the new full transactionSettings

  if (accessToken) {
    setAxiosAuth(accessToken);
  }

  axios
    .post(`/api/users/new-transaction-settings`, {
      transactionID,
      settingData: newTransactionSettings[transactionID],
    })
    .then((res) => {
      // Dispatch GET_USER_INFO is already done above
      return res;
    })
    .catch((err) => {
      let toSend = err;
      if (err.response) {
        toSend = err.response.data;
      }
      dispatch({
        type: GET_ERRORS,
        payload: toSend,
      });
    });

  // Ok - get the transaction list from state, copy it to a new variable,
  // update category, and call to reprocess
  // I THINK I COULD SIMPLIFY ALL OF THIS BY RETURNING TRANSACTIONS FROM SERVER
  // although, reprocessing on the server would mean either passing the server the
  // list of transactions or fetching from Plaid a fresh list
  // ------------------------------------------------------------------------
  const { transactions } = state.plaid;
  const newTransactionList = [...transactions];
  const copyGroupSumState = { ...state.plaid.categoryGroupSumObj };
  const copyTransactionCountPerCategory = {
    ...state.plaid.transactionCountPerCategory,
  };
  dispatch({
    type: TRANSACTIONS_LOADING,
  });
  for (
    let accountIndex = 0;
    accountIndex < newTransactionList.length;
    accountIndex++
  ) {
    const account = newTransactionList[accountIndex];
    const newInnerTransactions = [...account.transactions];
    for (
      let transIndex = 0;
      transIndex < newInnerTransactions.length;
      transIndex++
    ) {
      const transaction = newInnerTransactions[transIndex];

      if (transaction.transaction_id === transactionID) {
        const newTransaction = {
          ...transaction,
        };
        let newCategories = [];
        const oldGroup = whichGroupForCategory(
          newTransaction.category[0],
          state.plaid.allCategoriesArray
        );
        const oldGroupOldSum = copyGroupSumState[oldGroup];
        const amount = newTransaction.amount;

        if (isNewCategory) {
          newCategories = [...newTransaction.category];
          const newGroup = whichGroupForCategory(
            newCategoryName,
            state.plaid.allCategoriesArray
          );
          if (newGroup !== oldGroup) {
            let newGroupOldSum = 0;
            // Note it is possible that the new group doesn't have a sum yet
            if (copyGroupSumState[newGroup]) {
              newGroupOldSum = copyGroupSumState[newGroup];
            }

            copyGroupSumState[oldGroup] = oldGroupOldSum - amount;
            copyGroupSumState[newGroup] = newGroupOldSum + amount;

            copyTransactionCountPerCategory[oldGroup] -= 1;
            copyTransactionCountPerCategory[newGroup] += 1;
          }

          newCategories[0] = newCategoryName;
          newTransaction.category = newCategories;
        }
        if (isNewReviewed) {
          newTransaction.isReviewed = newReviewedState;
        }
        if (isNewDuplicate) {
          newTransaction.isDuplicate = newDuplicateState;
          // In case we are toggling a transaction to be ignored
          if (newDuplicateState) {
            copyGroupSumState[oldGroup] = oldGroupOldSum - amount;
            // Note - don't have to worry about income/expense sums here
            // because that is taken care of in processTransactionList
          } else {
            // Otherwise, it was an ignored transaction that is now being counted
            copyGroupSumState[oldGroup] = oldGroupOldSum + amount;
            // Note - don't have to worry about income/expense sums here
            // because that is taken care of in processTransactionList
          }
        }

        newInnerTransactions[transIndex] = newTransaction;
        newTransactionList[accountIndex] = {
          ...newTransactionList[accountIndex],
          transactions: newInnerTransactions,
        };
        transIndex = newInnerTransactions.length;
        accountIndex = newTransactionList.length;
      }
    }
  }
  // then dispatch process transaction list
  dispatch(
    processTransactionList(
      newTransactionList,
      state.auth.budgets,
      state.plaid.allCategoriesObj,
      state.plaid.allCategoriesArray,
      copyGroupSumState,
      copyTransactionCountPerCategory
    )
  );
  dispatch({
    type: GET_TRANSACTIONS,
    payload: newTransactionList,
  });
  // ------------------------------------------------------------------------
};

// userInfo - get user info (budgets, etc) for logged in user
export const getUserInfo = (accessToken, onBehalfID) => (
  dispatch,
  getState
) => {
  const state = getState();
  const newUser = { ...state.auth.user };
  if (accessToken) {
    setAxiosAuth(accessToken, onBehalfID);
  }
  axios
    .get("/api/users/user-info")
    .then((res) => {
      const returnedUser = res.data;
      const {
        budgets,
        expenseBudgetSum,
        spendRangeDays,
        perTransactionSettings,
        givePermToActAsMe,
        allowedToActAs,
      } = returnedUser;
      const payload = {
        budgets,
        expenseBudgetSum,
        spendRangeDays,
        perTransactionSettings,
      };
      if (
        givePermToActAsMe &&
        givePermToActAsMe.length &&
        givePermToActAsMe[0].id
      ) {
        newUser.givePermToActAsMe = givePermToActAsMe;
      }

      if (allowedToActAs && Object.keys(allowedToActAs).length) {
        if (!newUser.appData) {
          newUser.appData = {};
        }
        const newAppData = { ...newUser.appData };
        newAppData.allowedToActAs = allowedToActAs;
        newUser.appData = newAppData;
      }

      if (givePermToActAsMe || allowedToActAs) {
        payload.user = newUser;
      }

      dispatch({
        type: GET_USER_INFO,
        payload,
      });
    })
    .catch((err) => {
      if (err.response && err.response.status === 400) {
        console.log("Error 400 indicates user hasn't added any settings yet");
      }
      let toSend = err;
      if (err.response) {
        toSend = err.response.data;
      }
      dispatch({
        type: GET_ERRORS,
        payload: toSend,
      });
    });
};

export const setUserProfile = (accessToken, newProfile) => (dispatch) => {
  let saveAppData = {};
  if (accessToken) {
    setAxiosAuth(accessToken);
  }
  // We are using appData on the frontend, but don't want to save it to the server
  // But we have to save it because the frontend assumes it is there
  // TODO: this feels like a fragile approach
  if (newProfile.appData) {
    saveAppData = { ...newProfile.appData };
    delete newProfile.appData;
  }
  return axios
    .post("/api/users/setUserData", {
      user_data: newProfile,
    })
    .then((res) => {
      if (res.data) {
        // This check is to make sure we don't lose the appData field when getting userData from the server
        if (saveAppData && !res.data.appData) {
          res.data.appData = saveAppData;
        }
        if (!res.data.givePermToActAsMe) {
          res.data.givePermToActAsMe = [{}];
        }
        // Since the preferredName field is deprecated but still used by the mlp-client,
        // modify the response from the server if the server does not return that data
        if (!res.data.preferredName) {
          res.data.preferredName = "";
          if (newProfile.preferredName) {
            res.data.preferredName = newProfile.preferredName;
          }
        }
        return dispatch(setCurrentUser(res.data));
      }
      return res;
    })
    .catch((err) => {
      if (err && err.response && err.response.data) {
        console.error("Set profile error: " + err.response.data);
      }
      return err;
    });
};

// Set logged in user's profile
export const setCurrentUser = (user_profile) => {
  return {
    type: SET_CURRENT_USER,
    payload: user_profile,
  };
};

// Set current user to act on behalf of another user
export const setCurrentOnBehalfUser = (onBehalfUser) => (dispatch) => {
  // When dealing with loginAs - we should assume that neither user is first visit status
  dispatch({
    type: USER_FIRST_VISIT,
    payload: false,
  });
  dispatch({
    type: SET_CURRENT_ON_BEHALF_USER,
    payload: onBehalfUser,
  });
  // NOTE: could change the frontend state preferred name this way if we want
  if (onBehalfUser && onBehalfUser.email) {
    // Note that the frontend assumes that givePermToActAsMe[0] and appData.allowedToActAs will not be null
    dispatch({
      type: SET_CURRENT_USER,
      payload: {
        preferredName: onBehalfUser.email,
        givePermToActAsMe: [{}],
        appData: { allowedToActAs: {} },
      },
    });
  }
};

// Set logged in user budgets
export const setDaysRange = (accessToken, numberOfDays) => (
  dispatch,
  getState
) => {
  const state = getState();
  const accounts = state.plaid.accounts;

  const settingString = "spendRangeDays";
  const settingData = {};
  settingData[settingString] = numberOfDays;
  const postObject = {
    settingString,
    settingData,
  };

  // This commented out code works to reduce the transaction list on the frontend,
  // (ie, you can go from 30 days to 14 days) but can't expand again after that
  // So with it commented out - demos that are running without a frontend can't change date range
  //-----------------------------------------------------------------------------
  /*
    const oldTransactions = state.plaid.transactions;
    const newTransactionList = [];
    const momentToCheck = moment().subtract(numberOfDays, "days");
    oldTransactions.forEach((account) => {
    const newTransactions = account.transactions.filter((transaction) => {
      return moment(transaction.date).isAfter(momentToCheck);
    });
    const newAccount = { ...account, transactions: newTransactions };
    newTransactionList.push(newAccount);
  });

  // then dispatch process transaction list
  dispatch(processTransactionList(newTransactionList, state.auth.budgets));
  dispatch({
    type: GET_TRANSACTIONS,
    payload: newTransactionList,
  });
  dispatch({
    type: SET_SPEND_RANGE_DAYS_SELECTED,
    payload: numberOfDays,
  });*/
  //-----------------------------------------------------------------------------
  if (accessToken) {
    setAxiosAuth(accessToken);
  }

  axios
    .post(`/api/users/new-user-setting`, postObject)
    .then((res) => {
      dispatch(getTransactions(accessToken, accounts));
      dispatch({
        type: SET_SPEND_RANGE_DAYS_SELECTED,
        payload: numberOfDays,
      });
      return res;
    })
    .catch((err) => {
      let toSend = err;
      if (err.response) {
        toSend = err.response.data;
      }
      dispatch({
        type: GET_ERRORS,
        payload: toSend,
      });
    });
};

// Set logged in user budgets
export const setCurrentBudgets = (budgetPayload) => {
  return {
    type: SET_BUDGETS,
    payload: budgetPayload,
  };
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};
