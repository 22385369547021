import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { noDecimalCurrencyFormatter } from "../../utils/currencyFormatter";
//import percentFormatter from "../../utils/percentFormatter";
import Loading from "../../utils/loading.js";
import SpendRangeHeader from "../layout/SpendRangeHeader";
import DisplayCategoryCards from "./DisplayCategoryCards.js";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Accordion from "react-bootstrap/Accordion";
// Note: I'm going to add a date picker dropdown in this component
// And I'll need a getHistory function
// In this component (once we have a current-month-dashboard) we will be viewing historical story comparing
// budget to expenses in that month
// I guess by default then it would show the story of the previous month? Or maybe default is the last 30 days?
class SpendStory extends Component {
  render() {
    const { plaid, auth } = this.props;
    const userName = auth.user.preferredName;

    if (plaid.userFirstVisit) {
      return (
        <div>
          <Redirect to="/orientation" />
        </div>
      );
    } else if (!plaid.accounts || plaid.accounts.length === 0) {
      return (
        <div>
          <SpendRangeHeader
            mainHeaderText={userName}
            subHeaderText="Monthly Spend Story"
          />
          <Loading />
        </div>
      );
    }
    return (
      <>
        <div>
          <SpendRangeHeader
            mainHeaderText={userName}
            subHeaderText="Your past 30 Days"

          />
          <div className="section table-section spendStory">
            <Container>
              <Row className="barGraph">
                <Col xs={6} className="rightAlign">
                  <span className="largeNumber">
                    {noDecimalCurrencyFormatter.format(plaid.incomeSum)}
                    <span className="small bottom">Incoming</span>
                  </span>
                  <div className="flex">
                    <div
                      className="greenBar"
                      style={{
                        height:
                          (plaid.incomeSum /
                            (plaid.incomeSum + plaid.spendingSum)) *
                            100 +
                          "%",
                      }}
                    ></div>
                  </div>
                </Col>
                <Col xs={6} className="leftAlign">
                  <div className="flex">
                    <div
                      className="blueBar"
                      style={{
                        height:
                          (plaid.spendingSum /
                            (plaid.incomeSum + plaid.spendingSum)) *
                            100 +
                          "%",
                      }}
                    ></div>
                  </div>
                  <span className="largeNumber">
                    {noDecimalCurrencyFormatter.format(plaid.spendingSum)}
                    <span className="small bottom">Outgoing</span>
                  </span>
                </Col>
              </Row>
            </Container>
          </div>
          <div>
            <Container>
              <Row>
                <Col>
                  <h3>Spend Breakdown</h3>
                </Col>
              </Row>
              <Row>
                <Col>
                  <React.Fragment>
                    <Accordion defaultActiveKey="0" className="row months">
                      <Accordion.Toggle
                        as={Card.Header}
                        variant="link"
                        eventKey="0"
                      >
                        <div className="">
                          <h2 className="month">Essential </h2>
                          <p className="transactions">
                            The things you absolutely need
                          </p>
                          <p className="link">View Details</p>
                        </div>
                        <div className="">
                          <h2 className="firstLetter">
                            {plaid.categoryGroupSumObj &&
                            plaid.categoryGroupSumObj["Essentials"]
                              ? noDecimalCurrencyFormatter.format(
                                  plaid.categoryGroupSumObj["Essentials"]
                                )
                              : noDecimalCurrencyFormatter.format(0)}
                          </h2>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <DisplayCategoryCards
                          spendingByCategory={plaid.spendingByCategory}
                          groupName={"Essentials"}
                          spendingArray={plaid.allCategoriesArray}
                          transactionCountPerCategory={
                            plaid.transactionCountPerCategory
                          }
                        />
                      </Accordion.Collapse>
                    </Accordion>
                    <Accordion defaultActiveKey="1" className="row months">
                      <Accordion.Toggle
                        as={Card.Header}
                        variant="link"
                        eventKey="1"
                      >
                        <div className="">
                          <h2 className="month">Leʻa </h2>
                          <p className="transactions">The things you want</p>
                          <p className="link">View Details</p>
                        </div>
                        <div className="">
                          <h2 className="firstLetter">
                            {plaid.categoryGroupSumObj &&
                            plaid.categoryGroupSumObj["Leʻa"]
                              ? noDecimalCurrencyFormatter.format(
                                  plaid.categoryGroupSumObj["Leʻa"]
                                )
                              : noDecimalCurrencyFormatter.format(0)}
                          </h2>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <DisplayCategoryCards
                          spendingByCategory={plaid.spendingByCategory}
                          groupName={"Leʻa"}
                          spendingArray={plaid.allCategoriesArray}
                          transactionCountPerCategory={
                            plaid.transactionCountPerCategory
                          }
                        />
                      </Accordion.Collapse>
                    </Accordion>
                  </React.Fragment>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="section section-spending-plan">
            <div className="container">
              <div className="row">
                <div className="col">
                      <h2 className="large-number surplus">
                        {noDecimalCurrencyFormatter.format(
                          plaid.incomeSum - plaid.spendingSum
                        )}{" "}
                        <br />
                        <span className="small bottom">Gain</span>
                      </h2>
                      </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

SpendStory.propTypes = {
  plaid: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  plaid: state.plaid,
  auth: state.auth,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SpendStory);
