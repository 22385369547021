import React from "react";
/*import { Link } from "react-router-dom";*/
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row>
          <Col >
            <h4>Ua kukulu ʻia i Hawaiʻi me ke aloha nui</h4>
          </Col>
        {/*}<Col sm={6}>
            <div className="m-auto footer-nav">
              <Link to="/spend-story">spend story</Link>
              <Link to="/manage-transactions">transactions</Link>
              <Link to="/spend-plan">plan</Link>
              <Link to="/history">download</Link>
            </div>
          </Col>*/}
        </Row>

        <Row className="row text-center">
          <Col>
            <p>
              <small>&copy; 2020-{new Date().getFullYear()} WAIWAI</small>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
