import React, { useState } from "react";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import ColorHeader from "../layout/ColorHeader";
import LoggedInAsButton from "../layout/LoggedInAsButton.js";
import { setUserProfile } from "../../actions/authActions";

const UserProfilePage = (props) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { authUser, setUserProfile, currentOnBehalfUser } = props;
  const [showEditName, setShowEditName] = useState(false);
  const [editName, setEditName] = useState(authUser.preferredName);
  const [showEditLoginList, setShowEditLoginList] = useState(false);
  const [showSharedLogin, setShowSharedLogin] = useState(false);
  const [editLoginEmail, setEditLoginEmail] = useState(
    authUser.givePermToActAsMe[0].email
  );

  // Note that the input and save button should be disabled when loginAs mode is on
  // (it could be confusing for a user to change someone else's name)
  // But we also check here too just in case

  const onSaveName = (newName) => {
    if (currentOnBehalfUser._id) {
      return;
    }
    getAccessTokenSilently().then((accessToken) => {
      const trimmedNewName = newName.trim();
      // Ideally we probably want to popup an error if they enter a blank name
      if (
        trimmedNewName &&
        trimmedNewName !== authUser.preferredName &&
        trimmedNewName.length < 50
      ) {
        const sendObj = { ...authUser, preferredName: trimmedNewName };

        setUserProfile(accessToken, sendObj).then((result) => {
          if (result.response) {
            console.error("Error while trying to save preferred name");
            setEditName(authUser.preferredName);
            toast.error("Could not update name");
          } else {
            toast.success("Saved");
          }
        });
      } else {
        // If the newName is empty, we will reset the local state instead of saving
        setEditName(authUser.preferredName);
        toast.error("Could not update name");
      }
    });
  };

  const onRemoveLoginEmail = () => {
    // Since we only allow a user the ability to grant loginAs to one user, the email to remove is that first item in list
    const emailToRemove = authUser.givePermToActAsMe[0].email;
    const sendObj = {
      ...authUser,
      givePermToActAsMe: [{ id: "_REMOVE_" }],
    };
    onSaveLoginEmail(emailToRemove, sendObj);
  };

  // Note that the input and save button should be disabled when loginAs mode is on
  // (we don't want loginAs user to edit that user's list)
  // But we also check here too just in case

  // Note that inputObj is an optional parameter in case a manual object is desired (like in the remove case)
  const onSaveLoginEmail = (newEmail, inputObj) => {
    if (currentOnBehalfUser._id) {
      return;
    }
    getAccessTokenSilently().then((accessToken) => {
      let trimmedNewEmail = "";
      if (newEmail) {
        trimmedNewEmail = newEmail.trim();
      }
      // Right now we show an error toast if they don't change/remove an existing email but they might not see it
      // Also it won't send to server unless the existing email was changed or the remove button was clicked
      if (
        trimmedNewEmail &&
        (trimmedNewEmail !== authUser.givePermToActAsMe[0].email ||
          (inputObj &&
            inputObj.givePermToActAsMe[0] &&
            inputObj.givePermToActAsMe[0].id === "_REMOVE_")) &&
        trimmedNewEmail.length < 50
      ) {
        let sendObj = {
          ...authUser,
          givePermToActAsMe: [{ email: trimmedNewEmail }],
        };
        // If there is an inputObj passed (from the removeLogin function for example) - use that instead of default
        if (inputObj) {
          sendObj = inputObj;
        }

        setUserProfile(accessToken, sendObj).then((result) => {
          if (result.response) {
            console.error("Error while trying to save loginAs email");
            setEditLoginEmail(authUser.givePermToActAsMe[0].email);
            toast.error("Could not change permission");
          } else {
            toast.success("Saved");
            // In this case we still need to hide the edit input
            if (
              inputObj &&
              inputObj.givePermToActAsMe[0] &&
              inputObj.givePermToActAsMe[0].id === "_REMOVE_"
            ) {
              setShowEditLoginList(false);
              setEditLoginEmail("");
            }
          }
        });
      } else {
        // If setting user data doesn't work, we will reset the local state instead of saving
        setEditLoginEmail(authUser.givePermToActAsMe[0].email);
        toast.error("Could not change permission");
      }
    });
  };

  const handleNameEdits = (event) => {
    setEditName(event.target.value);
  };

  const handleLoginEmailEdits = (event) => {
    setEditLoginEmail(event.target.value);
  };

  const onNameButtonClick = () => {
    if (showEditName) {
      // User clicked save name
      onSaveName(editName);
    }
    setShowEditName(!showEditName);
  };

  const onLoginEmailButtonClick = () => {
    if (showEditLoginList) {
      // User clicked save email
      onSaveLoginEmail(editLoginEmail);
    }
    setShowEditLoginList(!showEditLoginList);
  };

  return (
    <>
      <ColorHeader
        mainHeaderText="Profile"
        subHeaderText="Manage your account"
        colorClassName="section-header-green"
      />
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <img
                className="profile-pic"
                src={user.picture}
                alt={authUser.preferredName}
              />
            </div>

            <div className="col-sm-7">
              {!currentOnBehalfUser._id ? (
                <>
                  <label className="bring-close">Name:</label>
                  <span className="editname">
                    {showEditName ? (
                      <input
                        type="text"
                        value={editName}
                        onChange={handleNameEdits}
                        disabled={currentOnBehalfUser._id}
                      ></input>
                    ) : (
                      editName
                    )}
                  </span>
                  <br />
                  <span className="notButton">
                    <button
                      className="small-button"
                      onClick={onNameButtonClick}
                      hidden={currentOnBehalfUser._id}
                      disabled={currentOnBehalfUser._id}
                    >
                      {showEditName ? "Save" : "Edit"}
                    </button>
                  </span>
                </>
              ) : (
                <></>
              )}
              <label className="edit-fixed">
                {currentOnBehalfUser._id ? "Real " : ""}
                Email:
              </label>{" "}
              {user.email}
              {/* <br />
              <br /> */}
              {/* <div className="sharedLogin">
                <h4>Shared Login</h4> */}
              {/* {!currentOnBehalfUser._id &&
              (showSharedLogin ||
                Object.keys(authUser.appData.allowedToActAs).length ||
                authUser.givePermToActAsMe[0].email) ? (
                <>
                  <label className="bring-close">Grant Shared Access:</label>
                  <span className="editname">
                    {showEditLoginList ? (
                      <input
                        type="text"
                        value={editLoginEmail}
                        onChange={handleLoginEmailEdits}
                        disabled={currentOnBehalfUser._id}
                      ></input>
                    ) : (
                      editLoginEmail
                    )}
                  </span>
                  <button
                    className="small-button"
                    onClick={onLoginEmailButtonClick}
                    hidden={currentOnBehalfUser._id}
                    disabled={currentOnBehalfUser._id}
                  >
                    {showEditLoginList
                      ? "Save"
                      : authUser.givePermToActAsMe[0].email
                      ? "Edit"
                      : "Add"}
                  </button>
                  <button
                    className="small-button"
                    onClick={onRemoveLoginEmail}
                    hidden={
                      currentOnBehalfUser._id ||
                      !showEditLoginList ||
                      !authUser.givePermToActAsMe[0].email ||
                      (editLoginEmail &&
                        editLoginEmail !== authUser.givePermToActAsMe[0].email)
                    }
                    disabled={
                      currentOnBehalfUser._id ||
                      !showEditLoginList ||
                      !authUser.givePermToActAsMe[0].email ||
                      (editLoginEmail &&
                        editLoginEmail !== authUser.givePermToActAsMe[0].email)
                    }
                  >
                    Remove
                  </button>
                  <br />
                  <LoggedInAsButton setProfileName={setEditName} />
                </>
              ) : (
                <button
                  className="small-button"
                  onClick={() => {
                    setShowSharedLogin(true);
                  }}
                  hidden={currentOnBehalfUser._id}
                  disabled={currentOnBehalfUser._id}
                >
                  Setup
                </button>
              )} */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  authUser: state.auth.user,
  accounts: state.plaid.accounts,
  currentOnBehalfUser: state.auth.currentOnBehalfUser,
});
const mapDispatchToProps = {
  setUserProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfilePage);
