import React from "react";
import LoginButton from "../../components/profile/LoginButton.js";
// Can add this code back in if we need an access check again in the future
import SignupButton from "../../components/profile/SignupButton.js";
//import { useState } from "react";
import Logo from "../../img/waiwai-logo2.svg";
//import Desktop1 from "../../img/homepage/organize.jpg";
//import Desktop2 from "../../img/homepage/plan.jpg";
const HomePage = (props) => {
  // Can add this code back in if we need an access check again in the future
  /*const [access, setAccess] = useState("");

  const onAccessChange = (event) => {
    let accessString = event.target.value.trim();
    setAccess(accessString);
  };*/

  return (
    <div className="container-fluid">
      <div className="waiwai-logo">
        <img
          alt="Waiwai Logo"
          src={Logo}
          width="30"
          height="30"
          className="d-inline-block align-top"
        />
        &nbsp;<span className="logo">waiwai</span>
      </div>
      <h1>
        Mālama Wai.
        <br />
        Ho'oulu Waiwai.
      </h1>
      <p>Manage your financial resources. Grow waiwai.</p>
      <div className="button-box">
        <LoginButton />
        <br />
        <SignupButton />
      </div>
    </div>
  );
};

export default HomePage;
