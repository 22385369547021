import axios from "axios";

const setAxiosAuth = (token, onBehalfID) => {
  if (token) {
    // Apply authorization token to every request if logged in
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    if (onBehalfID) {
      axios.defaults.headers.common["onBehalfID"] = onBehalfID;
    }
  } else {
    // Delete auth header
    delete axios.defaults.headers.common["Authorization"];
    delete axios.defaults.headers.common["onBehalfID"];
  }
};

export default setAxiosAuth;
