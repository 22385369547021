import React from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "./loading.js";
import Header from "../components/layout/Header.js";
import LoggedInAsHeader from "../components/layout/LoggedInAsHeader.js";
import Footer from "../components/layout/Footer.js";

// Note that hideHeader or hideFooter can be passed to the PrivateRoute component
const PrivateRoute = ({ component, hideHeader, hideFooter, ...args }) => (
  <>
    <div className="page-cotainer">
      {!hideHeader ? <LoggedInAsHeader /> : <></>}
      {!hideHeader ? <Header /> : <></>}
      <Route
        component={withAuthenticationRequired(component, {
          onRedirecting: () => <Loading />,
        })}
        {...args}
      />
    </div>
    {!hideFooter ? <Footer /> : <></>}
  </>
);

export default PrivateRoute;
