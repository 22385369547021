import React, { useState } from "react";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import {
  setCurrentOnBehalfUser,
  getUserInfo,
  setCurrentUser,
} from "../../actions/authActions";
import {
  setAccountsLoading,
  getAccounts,
} from "../../actions/accountActions.js";
import setAxiosAuth from "../../utils/setAxiosAuth.js";

const auth0Namespace =
  process.env.REACT_APP_AUTH0_AUDIENCE || "https://waiwai/auth";

// Note that the setProfileName prop is only used if the toggle-off button is clicked from the Profile page
const LoggedInAsButton = (props) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const {
    setCurrentOnBehalfUser,
    currentOnBehalfUser,
    getAccounts,
    getUserInfo,
    setCurrentUser,
    setProfileName,
    authUser,
    hideButton,
  } = props;

  const [realAuthUser, setRealAuthUser] = useState(authUser);

  React.useEffect(() => {
    let userData = user[auth0Namespace + "/user_data"];
    const appData = user[auth0Namespace + "/app_metadata"];
    // We have stopped using the preferredName field from auth0 now
    // that mlp-client uses the mano-wai backend (user.name will have the preferredName)
    userData = { ...userData, preferredName: user.name };
    // Note that we're storing the appData inside normal user_data spot
    userData.appData = appData;
    setRealAuthUser(userData);
  }, [user]);

  const toggleOnBehalf = (loginAsID, loginAsName) => {
    // This means we should toggle LoginAs off
    if (currentOnBehalfUser._id) {
      setCurrentOnBehalfUser({});
      // Note that the setProfileName prop is only used if
      // the toggle-off button is clicked from the Profile page (because it needs to go back to the real name)
      if (setProfileName) {
        setProfileName(realAuthUser.preferredName);
      }
      setCurrentUser(realAuthUser);
      // This will clear the on behalf header
      setAxiosAuth();
      getAccessTokenSilently().then((accessToken) => {
        getUserInfo(accessToken);
        getAccounts(accessToken);
      });
      // This will be to toggle LoginAs mode on
    } else {
      if (!loginAsID || !loginAsName) {
        // If we get the entries of the allowedToActAs object, we will have an array of possible users.
        // In the current implementation, we only support one user, so we take the first.
        // The structure of firstOnBehalfUser will be [0] is the id (key) and [1] will be the preferredName

        const firstOnBehalfUser = Object.entries(
          authUser.appData.allowedToActAs
        )[0];
        loginAsID = firstOnBehalfUser[0];
        loginAsName = firstOnBehalfUser[1];
      }

      setCurrentOnBehalfUser({
        _id: loginAsID,
        email: loginAsName,
      });
      // We don't want to change the edit name because the profile page will show the real user
      //setEditName(onBehalfUserDemo.email);
      getAccessTokenSilently().then((accessToken) => {
        getUserInfo(accessToken, loginAsID);
        getAccounts(accessToken, loginAsID);
      });
    }
  };

  if (
    !currentOnBehalfUser._id &&
    (!authUser ||
      !authUser.appData ||
      !authUser.appData.allowedToActAs ||
      !Object.keys(authUser.appData.allowedToActAs).length)
  ) {
    return <></>;
  }

  // If a user is logged-in-as someone else, we only show the button and the name
  else if (currentOnBehalfUser._id) {
    return (
      <>
        {hideButton ? (
          <></>
        ) : (
          <button className="small-button" onClick={toggleOnBehalf}>
            Back to my own account
          </button>
        )}
        <span>
          {currentOnBehalfUser.email
            ? " Currently viewing shared account: " + currentOnBehalfUser.email
            : ""}
        </span>
      </>
    );
  }

  // TODO: In the profile page (or in this component) need to list the available accounts to loginAs
  // If the user is not logged-in-as and they have permission, list all the available accounts
  else if (Object.keys(authUser.appData.allowedToActAs).length) {
    return (
      <>
        <label className="bring-close">Login as</label>
        {Object.entries(authUser.appData.allowedToActAs).map(([id, name]) => {
          return (
            <React.Fragment key={id}>
              <div className="row">
              <span className="name">{name}</span>
              <button
                className="small-button"
                onClick={() => toggleOnBehalf(id, name)}
              >
                Login
              </button>
              </div>
              <br />

            </React.Fragment>
          );
        })}
      </>
    );
  }

  return <></>;
};

const mapStateToProps = (state) => ({
  authUser: state.auth.user,
  accounts: state.plaid.accounts,
  currentOnBehalfUser: state.auth.currentOnBehalfUser,
});
const mapDispatchToProps = {
  setCurrentOnBehalfUser,
  setAccountsLoading,
  getAccounts,
  getUserInfo,
  setCurrentUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoggedInAsButton);
