import React, { useState } from "react";
import { connect } from "react-redux";
//import ColorHeader from "../layout/ColorHeader";
//import StackedLogo from "../../img/waiwai-LogoStacked.jpg";
// import Image1 from "../../img/onboarding/image1.svg";
// import Image2 from "../../img/onboarding/image2.svg";
// import Image3 from "../../img/onboarding/image3.svg";
// import Image4 from "../../img/onboarding/web1.gif";
// import Image4mobile from "../../img/onboarding/mobile1.gif";
// import Image5 from "../../img/onboarding/web2.gif";
// import Image5mobile from "../../img/onboarding/mobile2.gif";
// import Image6 from "../../img/onboarding/web3.gif";
// import Image6mobile from "../../img/onboarding/mobile3.gif";
// import Image7 from "../../img/onboarding/image7.svg";
// import Image8 from "../../img/onboarding/image8.svg";
//import { Link } from "react-router-dom";
//import Form from "react-bootstrap/Form";
import Carousel from "react-bootstrap/Carousel";
import { LinkContainer } from "react-router-bootstrap";
import { Redirect } from "react-router-dom";
import { setOrientationComplete } from "../../actions/accountActions.js";

const lastPageIndex = 2;

const Orientation = (props) => {
  const [index, setIndex] = useState(0);
  const [redirectString, setRedirectString] = useState("");

  const handleSelect = (selectedIndex, e) => {
    // If theyʻre navigating to the last page, consider their orientation complete after this
    if (selectedIndex === lastPageIndex) {
      props.setOrientationComplete();
    }
    // If the user is on the last page and they click the invisible next, take them to bank accounts
    if (index === lastPageIndex && selectedIndex === 0) {
      setRedirectString("/bank-accounts");
    } else {
      setIndex(selectedIndex);
    }
  };
  const directionButtons = (direction) => {
    if (index === lastPageIndex) {
      return <></>;
    }
    return (
      <span
        aria-hidden="true"
        className={direction === "Next" ? "button-next" : "button-prev"}
      >
        {direction}
      </span>
    );
  };
  if (redirectString !== "") {
    return <Redirect to={redirectString} />;
  }
  return (
    <>
      <div className=" instructions">
        <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          nextIcon={directionButtons("Next")}
          prevIcon={directionButtons("Previous")}
          interval={null}
        >
          <Carousel.Item>
            <Carousel.Caption>
              <h3>Build your foundation</h3>
              <p>
                Link your accounts to manage your balances all in one place.
              </p>
              <div className="onboarding"></div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Carousel.Caption>
              <h3>Chart your course</h3>
              <p>Use our simple form to create a monthly spending plan.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Carousel.Caption>
              <h3>Stay maka'ala</h3>
              <p>
                View your updated transactions from the website, or download the
                Waiwai Finance Mobile App to view it on the go.
              </p>
              <LinkContainer to="/bank-accounts">
                <button>ʻA ʻoia!</button>
              </LinkContainer>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    </>
  );
};

const mapStateToProps = null;
// If we did need to map redux state vars, would use this instead of null
/*const mapStateToProps = (state) => ({

});*/
const mapDispatchToProps = { setOrientationComplete };

export default connect(mapStateToProps, mapDispatchToProps)(Orientation);
