import { createSlice } from "@reduxjs/toolkit";

export const categoryFilterSlice = createSlice({
  name: "categoryFilter",
  initialState: [],
  reducers: {
    addCategory: function (state, action) {
      state.push(action.payload);
    },
    removeCategory: function (state, action) {
      state.filter((e) => e !== action.payload);
    },
    setCategory: function (state, action) {
      return [action.payload];
    },
  },
});
