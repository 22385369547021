import React from "react";
import { connect } from "react-redux";
import Navbar from "react-bootstrap/Navbar";
import LoggedInAsButton from "./LoggedInAsButton.js";

const LoggedInAsHeader = (props) => {
  const { currentOnBehalfUser } = props;

  return (
    <>
      {currentOnBehalfUser && currentOnBehalfUser._id ? (
        <Navbar sticky="top" bg="dark" variant="dark">
          <Navbar.Text>
            <LoggedInAsButton />
          </Navbar.Text>
        </Navbar>
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  currentOnBehalfUser: state.auth.currentOnBehalfUser,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LoggedInAsHeader);
