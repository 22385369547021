import React from "react";
import SpendCategoryCard from "./SpendCategoryCard";

const DisplayCategoryCards = (props) => {
  /*let groupName = "";
  if (props.groupName) {
    groupName = props.groupName;
  }*/
  if (
    /*!props.sortedCategoriesUnderBudget ||
    !props.sortedCategoriesUnderBudget.length ||
    !props.sortedCategoriesOverBudget ||
    !props.sortedCategoriesOverBudget.length ||
    !props.budgets ||*/
    !props.spendingByCategory ||
    !props.spendingArray ||
    !props.transactionCountPerCategory
  ) {
    return <>No category budget/expense data found</>;
  }
  return (
    <div className="section section-spend-categories">
      <div className="container">
        {/*<div className="row">
          <div className="col-8 pt-4 pb-4">
            <h4>
              On track
              <br />
              <span className="small bottom">
                {props.sortedCategoriesUnderBudget.length}
                {props.sortedCategoriesUnderBudget.length === 1
                  ? " category"
                  : " categories"}
              </span>
            </h4>
          </div>
        </div>*/}
        <div className="row">
          {props.spendingArray.map((categoryObj, i) => {
            let categoryName = categoryObj.name;
            if (categoryName.includes("Income")) {
              return <React.Fragment key={categoryName}></React.Fragment>;
            }
            if (categoryObj.group !== props.groupName) {
              return <React.Fragment key={categoryName}></React.Fragment>;
            }
            if (
              !props.spendingByCategory[categoryName] ||
              props.spendingByCategory[categoryName] === 0
            ) {
              return <React.Fragment key={categoryName}></React.Fragment>;
            }
            return (
              <div key={categoryName} className="col-lg-3 col-md-4 col-sm-6">
                <SpendCategoryCard
                  categoryName={categoryName}
                  spentAmount={props.spendingByCategory[categoryName]}
                  transactionCount={
                    props.transactionCountPerCategory[categoryName]
                  }
                ></SpendCategoryCard>
              </div>
            );
          })}
        </div>
        {/*<div className="row">
          <div className="col-8 pt-4 pb-4">
            <h4>
              Needs Work
              <br />
              <span className="small bottom">
                {props.sortedCategoriesOverBudget.length}
                {props.sortedCategoriesOverBudget.length === 1
                  ? " category"
                  : " categories"}
              </span>
            </h4>
          </div>
        </div>
        <div className="row">
          {props.sortedCategoriesOverBudget.map((categoryName, i) => {
            if (categoryName.includes("Income")) {
              return <React.Fragment key={categoryName}></React.Fragment>;
            }
            return (
              <div key={categoryName} className="col-lg-3 col-md-4 col-sm-6">
                <SpendCategoryCard
                  categoryName={categoryName}
                  underBudget={false}
                  budgetAmount={props.budgets[categoryName]}
                  spentAmount={props.spendingByCategory[categoryName]}
                ></SpendCategoryCard>
              </div>
            );
          })}
        </div>*/}
      </div>
    </div>
  );
};

export default DisplayCategoryCards;
