import React, { useState } from "react";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import Accordion from "react-bootstrap/Accordion";
// import Tabs from "react-bootstrap/Tabs";
// import Tab from "react-bootstrap/Tab";
import Card from "react-bootstrap/Card";
// This is the library Joe suggested for multilevel dropdown
//import Dropdown from "react-multilevel-dropdown";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
// import Container from "react-bootstrap/Container";
import axios from "axios";
import setAxiosAuth from "../../../utils/setAxiosAuth.js";
import Loading from "../../../utils/loading.js";
import { whichGroupForCategory } from "../../../utils/waiwaiCategories.js";
import { noDecimalCurrencyFormatter } from "../../../utils/currencyFormatter.js";
import { setTransactionSettings } from "../../../actions/authActions.js";
import { addTransaction } from "../../../actions/accountActions.js";
import CashTransactionForm from "../CashTransactionForm.js";
import ActivityTransactionsList from "./ActivityTransactionsList.js";

const deepMergeArraysNoDupes = function (array1, array2) {
  let array3 = array1.concat(array2);
  array3 = [...new Set([...array1, ...array2])];
  return array3;
};

const sortDateString = function (a, b) {
  return new Date(b) - new Date(a);
};

const updateTypeLists = (
  newTransaction,
  typeIDsByDate,
  setTypeIDsByDate,
  allTypeDates,
  setAllTypeDates
) => {
  const copyTypeIDsByDate = { ...typeIDsByDate };

  // If allTypeDates already has this date, we can just put in the existing list
  if (typeIDsByDate[newTransaction.date]) {
    const newIDsByDateList = [].concat(
      typeIDsByDate[newTransaction.date],
      newTransaction.transaction_id
    );
    copyTypeIDsByDate[newTransaction.date] = newIDsByDateList;
  } else {
    // Otherwise need to add in the new date, sort the list, and put in the id to new list
    const newIDsByDateList = [newTransaction.transaction_id];
    copyTypeIDsByDate[newTransaction.date] = newIDsByDateList;
    const newTypeDates = [newTransaction.date].concat(allTypeDates);
    newTypeDates.sort(sortDateString);
    setAllTypeDates(newTypeDates);
  }
  setTypeIDsByDate(copyTypeIDsByDate);
};

const OneMonthActivity = (props) => {
  //const isSandbox = process.env.REACT_APP_PLAID_ENV_STRING === "sandbox";
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [incomeSum, setIncomeSum] = useState(0);
  const [expenseSum, setExpenseSum] = useState(0);
  const [transactionCount, setTransactionCount] = useState(0);
  const [ignoreCount, setIgnoredCount] = useState(0);
  const [cashState, setCashState] = useState("");
  const [groupSumState, setGroupSumState] = useState({});
  const [transactionMap, setTransactionMap] = useState({});
  const [combinedIDsByDate, setCombinedIDsByDate] = useState({});
  const [allCombinedDates, setAllCombinedDates] = useState([]);
  // whichMonth is the month moment for this one-month view
  const {
    whichMonth,
    accounts,
    allCategoriesArray,
    currentOnBehalfUser,
  } = props;

  React.useEffect(() => {
    getHistory(whichMonth);
  }, [whichMonth, accounts]);

  const updateOneTransaction = (transaction_id, propName, propVal) => {
    const transaction = transactionMap[transaction_id];
    const copyGroupSumState = { ...groupSumState };
    const oldGroup = whichGroupForCategory(
      transaction.category[0],
      allCategoriesArray
    );
    const oldGroupOldSum = groupSumState[oldGroup];
    const amount = transaction.amount;

    const copyAllTransactions = {
      ...transactionMap,
    };
    const copyTransaction = {
      ...transactionMap[transaction_id],
    };

    if (propName === "category") {
      const updatedCategory = propVal;
      const newGroup = whichGroupForCategory(
        updatedCategory,
        allCategoriesArray
      );
      if (newGroup !== oldGroup) {
        let newGroupOldSum = 0;
        // Note it is possible that the new group doesn't have a sum yet
        if (groupSumState[newGroup]) {
          newGroupOldSum = groupSumState[newGroup];
        }

        copyGroupSumState[oldGroup] = oldGroupOldSum - amount;
        copyGroupSumState[newGroup] = newGroupOldSum + amount;
      }
      copyTransaction.category = [updatedCategory, transaction.category[1]];
    } else if (propName === "isDuplicate") {
      // In case we are toggling a transaction to be ignored
      if (propVal) {
        copyGroupSumState[oldGroup] = oldGroupOldSum - amount;
        // Amounts are positive if they are an expense
        if (amount < 0) {
          // Adding the negative amount to the income sum will reduce income sum
          setIncomeSum(incomeSum + amount);
        } else {
          setExpenseSum(expenseSum - amount);
        }
      } else {
        // Otherwise, it was an ignored transaction that is now being counted
        copyGroupSumState[oldGroup] = oldGroupOldSum + amount;
        // Amounts are positive if they are an expense
        if (amount < 0) {
          // Subtracting the negative amount from the income sum will increase income sum
          setIncomeSum(incomeSum - amount);
        } else {
          setExpenseSum(expenseSum + amount);
        }
      }

      copyTransaction.isDuplicate = propVal;
    }

    copyAllTransactions[transaction_id] = copyTransaction;
    setTransactionMap(copyAllTransactions);
    setGroupSumState(copyGroupSumState);
  };

  const onCashToggle = () => {
    if (currentOnBehalfUser._id) {
      toast.error("Shared accounts are view-only");
      return;
    }
    if (cashState === "") {
      setCashState("0");
    } else {
      setCashState("");
    }
  };

  /*const exampleCashTransaction = {
      account_id: "Cash",
      date: "2021-01-20",
      amount: 100.01,
      name: "Housing raw name",
      merchant_name: "This is the cleaned up Housing",
      pending: false,
      category: ["Personal", "Essentials"],
      // Note that _id is created automatically from user_id, item_id (account_id), and unix timestamp
  };*/
  const clickAdd = (inputTransaction) => {
    const copyGroupSumState = { ...groupSumState };
    if (inputTransaction.isIncome === "true") {
      // To properly add to the income total, need to add before sending to server
      // But we'll update the income and expense state sums after server response
      inputTransaction.amount *= -1;
    }
    // Now that we've used the isIncome flag, we don't want to send it to the server
    inputTransaction.isIncome = null;
    delete inputTransaction.isIncome;

    getAccessTokenSilently().then((accessToken) => {
      props
        .addTransaction(accessToken, inputTransaction)
        .then((newTransaction) => {
          if (newTransaction && newTransaction.transaction_id) {
            const oldGroup = whichGroupForCategory(
              newTransaction.category[0],
              allCategoriesArray
            );

            let oldGroupOldSum = 0;
            // Need to check if there is anything defined because it might be totally new
            if (groupSumState[oldGroup]) {
              oldGroupOldSum = groupSumState[oldGroup];
            }

            // Need to put the new transaction into our overall map, and into the income/expense and by date structures
            const copyMap = { ...transactionMap };
            copyMap[newTransaction.transaction_id] = newTransaction;
            setTransactionMap(copyMap);

            // Amount greater than zero means an expense
            if (newTransaction.amount > 0) {
              setExpenseSum(expenseSum + newTransaction.amount);
            } else {
              const positiveIncome = newTransaction.amount * -1;
              setIncomeSum(incomeSum + positiveIncome);
            }
            updateTypeLists(
              newTransaction,
              combinedIDsByDate,
              setCombinedIDsByDate,
              allCombinedDates,
              setAllCombinedDates
            );
            // Can treat like an ignored transaction that is now being counted
            copyGroupSumState[oldGroup] =
              oldGroupOldSum + newTransaction.amount;
            setGroupSumState(copyGroupSumState);

            // Make sure to increase the number of transactions
            setTransactionCount(transactionCount + 1);
          }
        });
    });
  };

  const onCategorySelect = (
    eventkey,
    event,
    update_id,
    updateValue,
    tableMeta
  ) => {
    toast.success("Saved");
    const transactionPayload = {
      transactionID: update_id,
      newMainCategory: eventkey,
      newGroup: whichGroupForCategory(eventkey, allCategoriesArray),
      newReviewedState: true,
    };
    console.log(transactionPayload);
    updateOneTransaction(update_id, "category", eventkey);
    getAccessTokenSilently().then((accessToken) => {
      props.setTransactionSettings(accessToken, transactionPayload);
    });
  };

  // NOTE/TODO: We should update the expense/income totals when duplicate is clicked
  const onDuplicateClick = (duplicate_obj, updateValue, tableMeta) => {
    const newDuplicateState = !duplicate_obj.isDuplicate;

    if (newDuplicateState) {
      toast.success("Ignored");
      setTransactionCount(transactionCount - 1);
      setIgnoredCount(ignoreCount + 1);
    } else {
      toast.success("Restored");
      setTransactionCount(transactionCount + 1);
      setIgnoredCount(ignoreCount - 1);
    }

    const transactionPayload = {
      transactionID: duplicate_obj.id,
      newDuplicateState,
    };
    updateOneTransaction(duplicate_obj.id, "isDuplicate", newDuplicateState);
    console.log(transactionPayload);
    getAccessTokenSilently().then((accessToken) => {
      props.setTransactionSettings(accessToken, transactionPayload);
    });
  };

  // Get Historical transactions with Auth0
  const getHistory = (startDate) => {
    // Don't need this line from the historical component because it is in our props
    //setWhichMonth(moment(startDate).format("MMM YYYY"));
    getAccessTokenSilently().then((accessToken) => {
      // Note that server will process requests for dates assuming UTC, so strip timezone when requesting
      const plaidData = {
        // Accounts come from redux in parent component for server to fetch by account id
        accounts,
        startDate: moment(startDate).utcOffset(0),
        endDate: moment(startDate).utcOffset(0).endOf("month"),
      };
      if (accessToken) {
        setAxiosAuth(accessToken);
      }
      if (plaidData && plaidData.accounts.length !== 0) {
        // This call will load the historical transaction data
        axios
          .post("/api/plaid/accounts/history/transactions", plaidData)
          .then((res) => {
            // Need to check if there are transactions?
            if (res.data.transactions) {
              //setTransactions(makeTableData(res.data.transactions));
              const calcCombinedIDsByDate = {};
              for (const key in res.data.expenseIDsByDate) {
                const thisExpenseArray = res.data.expenseIDsByDate[key];
                calcCombinedIDsByDate[key] = [...thisExpenseArray];
              }
              for (const key in res.data.incomeIDsByDate) {
                const thisIncomeArray = res.data.incomeIDsByDate[key];
                const thisCombinedArray = calcCombinedIDsByDate[key];
                if (thisCombinedArray) {
                  calcCombinedIDsByDate[key] = thisCombinedArray.concat(
                    thisIncomeArray
                  );
                } else {
                  calcCombinedIDsByDate[key] = [...thisIncomeArray];
                }
              }
              const calcAllCombinedByDate = deepMergeArraysNoDupes(
                res.data.allExpenseDates,
                res.data.allIncomeDates
              );
              setExpenseSum(res.data.expenseSum);
              setIncomeSum(res.data.paycheckSum + res.data.otherIncomeSum);
              setTransactionCount(res.data.transactionCount);
              setIgnoredCount(res.data.totalIgnored);
              setGroupSumState(res.data.returnGroupsSums);
              setTransactionMap(res.data.transactionMap);
              setCombinedIDsByDate(calcCombinedIDsByDate);
              setAllCombinedDates(calcAllCombinedByDate);
            }
            if (!res.data.transactions || !res.data.transactions.length) {
              // Should throw no a tranasactions error here
              console.log("no transaction history found");
              setExpenseSum(0);
              setIncomeSum(0);
              setTransactionCount(0);
              setIgnoredCount(0);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  return !isAuthenticated ? (
    <Loading />
  ) : (
    <>
      <Accordion className="row months">
        <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
          <div className="">
            <h2 className="month">{whichMonth.format("MMM")} </h2>
            <p className="transactions">
              {transactionCount} transactions, {ignoreCount} ignored
            </p>
            <p className="link">View Transactions</p>
          </div>
          <div className="">
            <h2 className="plusminus firstLetter">
              {noDecimalCurrencyFormatter.format(incomeSum - expenseSum)}
            </h2>
          </div>
        </Accordion.Toggle>

        <Accordion.Collapse eventKey="0">
          <div className="row monthData">
            <div className="cashtransaction container">
              <React.Fragment>
                <Accordion className="hide-show" activeKey={cashState}>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    className="notButton toggle"
                    eventKey="0"
                    onClick={onCashToggle}
                  >
                    + Cash Transaction
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey="0">
                    <CashTransactionForm
                      sendSubmit={(inputData) => {
                        clickAdd(inputData);
                        onCashToggle();
                      }}
                    />
                  </Accordion.Collapse>
                </Accordion>
              </React.Fragment>
            </div>

            <div className="tabs container">
              <Row className="Summary">
                <Col md={{ span: 6 }}>
                  <h2 className="firstLetter">
                    {noDecimalCurrencyFormatter.format(incomeSum)}
                    <span className="label">Incoming</span>
                  </h2>
                </Col>{" "}
                <Col md={{ span: 6 }}>
                  <h2 className="firstLetter">
                    {noDecimalCurrencyFormatter.format(expenseSum)}
                    <span className="label">Outgoing</span>
                  </h2>
                  <br />
                  <h4 className="firstLetter">
                    {groupSumState["Essentials"]
                      ? noDecimalCurrencyFormatter.format(
                          groupSumState["Essentials"]
                        )
                      : noDecimalCurrencyFormatter.format(0)}
                    <span className="label">Essentials </span>
                  </h4>
                  <h4 className="firstLetter">
                    {groupSumState["Leʻa"]
                      ? noDecimalCurrencyFormatter.format(groupSumState["Leʻa"])
                      : noDecimalCurrencyFormatter.format(0)}
                    <span className="label">Leʻa </span>
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col md={{ span: 6, offset: 3 }}>
                  <ActivityTransactionsList
                    allTypeDates={allCombinedDates}
                    typeIDsByDate={combinedIDsByDate}
                    transactionMap={transactionMap}
                    clickAdd={clickAdd}
                    onCategorySelect={onCategorySelect}
                    onDuplicateClick={onDuplicateClick}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Accordion.Collapse>
      </Accordion>
    </>
  );
};

const mapStateToProps = (state) => ({
  currentOnBehalfUser: state.auth.currentOnBehalfUser,
});

const mapDispatchToProps = { setTransactionSettings, addTransaction };

export default connect(mapStateToProps, mapDispatchToProps)(OneMonthActivity);
