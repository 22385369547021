import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { useAuth0 } from "@auth0/auth0-react";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import Auth0ProviderWithHistory from "./utils/auth0-provider-with-history.js";
import PrivateRoute from "./utils/privateRoute.js";
import Loading from "./utils/loading.js";
import ScrollToTop from "./utils/ScrollToTop.js";
import "./App.scss";
import LogoutButton from "./components/profile/LogoutButton.js";
import HomePage from "./components/layout/HomePage.js";
import SpendStory from "./components/spend/SpendStory.js";
import EasyHome from "./components/spend/EasyHome.js";
import SpendPlan from "./components/spend/SpendPlan.js";
import BankAccountsPage from "./components/profile/BankAccountsPage.js";
import UserProfilePage from "./components/profile/UserProfilePage.js";
import rootReducer from "./reducers";
import AccountBootstrap from "./components/layout/TransactionBootstrap.js";
import Orientation from "./components/profile/Orientation.js";
import HistoricalTable from "./components/spend/HistoricalTable.js";
import AllMonthlyActivity from "./components/spend/byMonth/AllMonthlyActivity.js";
import { useTracking } from "./utils/useTracking.js";

const apiRoot = process.env.REACT_APP_APIROOT || "";
axios.defaults.baseURL = apiRoot;

const store = configureStore({
  reducer: rootReducer,
});

const Routing = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  const isLiveData = process.env.REACT_APP_LIVEDATA === "true";
  const accessCheck = process.env.REACT_APP_ACCESSCHECK
    ? process.env.REACT_APP_ACCESSCHECK
    : "";
  //note prod is G-PGK7QXJFDX
  //note staging is G-S290C2PN9G
  const trackingId = process.env.REACT_APP_GOOG_ANALYTICS_CODE
    ? process.env.REACT_APP_GOOG_ANALYTICS_CODE
    : null;

  useTracking(trackingId);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {isAuthenticated && isLiveData ? <AccountBootstrap /> : <></>}
      <Switch>
        <Route exact path={process.env.PUBLIC_URL + "/"}>
          {isAuthenticated ? (
            <Redirect to="/easy-home" />
          ) : (
            <HomePage accessCheck={accessCheck} />
          )}
        </Route>
        <Route path="/logout">
          <LogoutButton />
        </Route>
        <PrivateRoute path="/easy-home" component={EasyHome} />
        <PrivateRoute path="/spend-story" component={SpendStory} />

        <PrivateRoute path="/spend-plan" component={SpendPlan} />
        <PrivateRoute
          path="/manage-transactions"
          component={AllMonthlyActivity}
        />
        <PrivateRoute path="/user-profile" component={UserProfilePage} />
        <PrivateRoute path="/bank-accounts" component={BankAccountsPage} />
        <PrivateRoute
          path="/orientation"
          hideHeader={true}
          hideFooter={true}
          component={Orientation}
        />
        <PrivateRoute path="/history" component={HistoricalTable} />
      </Switch>
    </>
  );
};

function App() {
  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <ToastContainer
          position="top-right"
          autoClose={1500}
          draggable={false}
          hideProgressBar
          newestOnTop
        />
        <Auth0ProviderWithHistory>
          <Routing />
        </Auth0ProviderWithHistory>
      </Router>
    </Provider>
  );
}

export default App;
