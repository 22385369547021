import React, { useCallback } from "react";
import { usePlaidLink } from "react-plaid-link";
// Can use the following to test component version of Plaid Link
//import { PlaidLink } from "react-plaid-link";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import axios from "axios";
import setAxiosAuth from "../utils/setAxiosAuth.js";
import {
  addAccount,
  refreshAccount,
  deleteAccount,
} from "../actions/accountActions";
import { useAuth0 } from "@auth0/auth0-react";

const ExportDeleteAccountButton = (props) => {
  const { account, deleteAccount } = props;
  const accountID = account._id;
  const { getAccessTokenSilently } = useAuth0();

  const onDeleteClick = useCallback(() => {
    // send token to server
    const plaidData = {
      id: accountID,
    };
    getAccessTokenSilently().then((accessToken) => {
      deleteAccount(accessToken, plaidData);
    });
  }, [accountID, getAccessTokenSilently, deleteAccount]);

  // itemId could be Cash and then it is our first manual account which cannot be deleted
  if (account.itemId === "Cash") {
    return <Button disabled={true}>Delete</Button>;
  }

  return <Button onClick={onDeleteClick}>Delete</Button>;
};

const deleteMapStateToProps = (state) => ({
  accounts: state.plaid.accounts,
  accountsLoading: state.plaid.accountsLoading,
});

export const DeleteAccountButton = connect(deleteMapStateToProps, {
  deleteAccount,
})(ExportDeleteAccountButton);

const PlaidToExport = (props) => {
  //const isSandbox = process.env.REACT_APP_PLAID_ENV_STRING === "sandbox";
  const { refreshAccount, addAccount } = props;
  const { getAccessTokenSilently } = useAuth0();

  // In theory you can tell the Plaid Link to get an Auth0 token when it opens
  /*const onOpen = useCallback((eventName, metadata)) => {
      if (eventName === "OPEN") { .... }
  },[getAccessTokenSilently]);*/

  const plaidOnExit = useCallback(
    (err, metadata) => {
      console.log("This means plaidOnExit was called (not just errors)");

      let checkErr = err;
      /*if (isSandbox && err === null) {
        checkErr = {
          error_type: "NOT-ERROR",
          error_code: "NOT-CODE",
          display_message:
            "This is a non-error Sandbox onExit Plaid link event",
        };
      }*/

      // We only send the data to the server for review for errors (or in sandbox env)
      if (checkErr) {
        getAccessTokenSilently().then((accessToken) => {
          setAxiosAuth(accessToken);
          console.log("Plaid link had error, posting to our server");
          axios
            .post("/api/plaid/log", { err: checkErr, metadata })
            .then((res) => {
              console.log("Server reports error logged");
            })
            .catch((postError) => {
              console.log(postError);
            });
        });
      }
    },
    [getAccessTokenSilently]
  );

  const newAccountOnSuccess = useCallback(
    (token, metadata) => {
      // send token to server
      const plaidData = {
        public_token: token,
        metadata: metadata,
      };
      getAccessTokenSilently().then((accessToken) => {
        addAccount(accessToken, plaidData);
      });
    },
    [addAccount, getAccessTokenSilently]
  );
  const refreshAccountOnSuccess = useCallback(
    (token, metadata) => {
      // send token to server
      const plaidData = {
        public_token: token,
        metadata: metadata,
      };

      getAccessTokenSilently().then((auth0token) => {
        refreshAccount(auth0token, plaidData);
      });
    },
    [getAccessTokenSilently, refreshAccount]
  );

  const config = {
    //token: "",
    clientName: process.env.REACT_APP_NAME,
    publicKey: process.env.REACT_APP_PLAID_PUBLIC_KEY,
    env: process.env.REACT_APP_PLAID_ENV_STRING,
    product: ["transactions"],
    onSuccess: newAccountOnSuccess,
    onExit: plaidOnExit,
    // ...
  };
  if (process.env.REACT_APP_HERON_PLAID_WEBHOOK) {
    config.webhook = process.env.REACT_APP_HERON_PLAID_WEBHOOK;
    console.log("setting Heron's webhook url in the plaid configuration");
  }

  if (props.existingAccount && props.existingAccount.toRefresh) {
    config.token = props.existingAccount.publicToken;
    config.onSuccess = refreshAccountOnSuccess;
  }

  const { open, ready /*, error*/ } = usePlaidLink(config);

  // itemId could be Cash and then it is our first manual account which cannot be deleted
  if (props.existingAccount && props.existingAccount.itemId === "Cash") {
    return <></>;
  }

  return (
    <>
      {/* Note this button is used to refresh accounts and also add new accounts
      depending on what props are supplied*/}
      <Button
        className={props.buttonClass ? props.buttonClass : ""}
        onClick={() => open()}
        disabled={
          !ready || (props.existingAccount && !props.existingAccount.toRefresh)
        }
      >
        {props.buttonText}
      </Button>
      {/* Can use the following to test component version of Plaid Link*/}
      {/*<PlaidLink {...config}>Connect a bank account</PlaidLink>*/}
    </>
  );
};

PlaidToExport.displayName = "Plaid";

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  addAccount,
  refreshAccount,
};

// Note that there is probably a better way to do this with React hooks now
export const PlaidButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlaidToExport);
