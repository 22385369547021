import React from "react";

const ColorHeader = (props) => {
  return (
    <>
      <div className="section-space"></div>
      <div className={"section " + props.colorClassName}>
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-xl-6"></div>
          </div>
        </div>

      <div className="container">
        <div className="row">
          <div className="col">
            <h1>{props.mainHeaderText}</h1>
            <p>{props.subHeaderText}</p>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

// Note that there is probably a better way to do this with React hooks now
export default ColorHeader;
