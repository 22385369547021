export const defaultCategoriesThisSpendRange = [
  {
    x: 11,
    name: "Savings",
  },
  {
    x: 1,
    name: "Housing",
  },
  {
    x: 2,
    name: "Transportation",
  },
  {
    x: 3,
    name: "Food",
  },
  {
    x: 4,
    name: "Utilities",
  },
  {
    x: 5,
    name: "Insurance & Healthcare",
  },
  {
    x: 6,
    name: "Debt",
  },
  {
    x: 7,
    name: "Personal",
  },
  {
    x: 8,
    name: "Education",
  },
  {
    x: 9,
    name: "Child Care",
  },
  {
    x: 10,
    name: "Entertainment",
  },
  {
    x: 12,
    name: "Income",
  },
];
export const defaultSpendingByCategory = {
  Housing: 0,
  Transportation: 0,
  Food: 0,
  Utilities: 0,
  "Insurance & Healthcare": 0,
  Debt: 0,
  Personal: 0,
  Education: 0,
  "Child Care": 0,
  Entertainment: 0,
  Savings: 0,
  Income: 0,
};

// Array Style Categories from the API server look like this:
/*
  ["Housing", "Rent/Mortgage"],
  ["Housing", "Electricity"],
  ["Transportation", "Gas"],
  ["Transportation", "Car Payment"],
  ["Transportation", "Repairs/Maintenance"],
  etc
*/

// Note though that a category object from Plaid can look like this:
/*
{
  "category_id": "18024001",
  "group": "place",
  "hierarchy": [
    "Service",
    "Home Improvement",
    "Upholstery"
  ]
}
*/

// So we create a category list for the frontend like this:
/*
[
  {
    name: "Rent/Mortgage",
    hierarchy: ["Housing", "Rent/Mortgage"]
  },
  {
    name: "Gas",
    hierarchy: ["Transportation", "Gas"]
  }
]
*/
// This is an idea for a function that could take arbitrary depth of hierarchy
/*export const generateWaiwaiFormatCategories = (arrayStyleCategories) => {
  // This is part of the old logic that was hardcoded for 2 layers
  //-------------------------------------------------------------------------------

  // const returnData = {};
  // returnData.categoryArray = [];
  // returnData.categoryObj = {};
  // returnData.groupSums = {};

  //-------------------------------------------------------------------------------

  // When we get a hierarchy list, which one will we use as the category name?
  // By setting this to 1, we are going to use the top level subcategory as the main category name
  const hieracrchyIndexForName = 1;
  const retData = {};
  retData.categoryArray = [];
  retData.categoryObj = {};
  // This is setup by default to handle sums for 2 layers deep of categories, but the logic below
  // can do deeper into categories if that is what the server passes us
  const hierarchySums = {
    "hierarchy.0": {},
    "hierarchy.1": {},
  };

  if (arrayStyleCategories && arrayStyleCategories.length) {
    for (let a = 0; a < arrayStyleCategories.length; a++) {
      // This is part of the old logic that was hardcoded for 2 layers
      //-------------------------------------------------------------------------------

      // let thisCategory = arrayStyleCategories[a];
      // if (thisCategory && thisCategory.length && thisCategory.length > 1) {
      //   let thisCatGroup = thisCategory[0];
      //   let thisCatMainName = thisCategory[1];
      //   // Note this structure has additional data compared to what we had before
      //   returnData.categoryArray.push({
      //     name: thisCatMainName,
      //     group: thisCatGroup,
      //   });
      //   returnData.categoryObj[thisCatMainName] = 0;
      //   if (returnData.groupSums[thisCatGroup] === undefined) {
      //     returnData.groupSums[thisCatGroup] = 0;
      //   }
      // }
      //-------------------------------------------------------------------------------

      // This is the new arbitrary number of category layers logic (not just 2)
      let thisCatList = arrayStyleCategories[a];
      if (thisCatList && thisCatList.length && thisCatList.length > 1) {
        const pushObject = {};
        pushObject.hierarchy = [];
        for (let catInd = 0; catInd < thisCatList.length; catInd++) {
          let thisCatStr = thisCatList[catInd];
          pushObject.hierarchy.push(thisCatStr);
          if (hierarchySums["hierarchy." + catInd] === undefined) {
            hierarchySums["hierarchy." + catInd] = {};
          }
          if (hierarchySums["hierarchy." + catInd][thisCatStr] === undefined) {
            hierarchySums["hierarchy." + catInd][thisCatStr] = 0;
          }
        }

        pushObject.name = thisCatList[hieracrchyIndexForName];
        retData.categoryArray.push(pushObject);
        if (retData.categoryObj[pushObject.name] === undefined) {
          retData.categoryObj[pushObject.name] = 0;
        }
      }
    }
    retData.hierarchySums = hierarchySums;
    return retData;
    //return returnData;
  }
};*/

// This function is in use, other function above is for prototyping/notes
export const generateWaiwaiFromTwoLayerCategories = (arrayStyleCategories) => {
  // This is the logic that was hardcoded for 2 layers
  //-------------------------------------------------------------------------------
  const returnData = {};
  returnData.categoryArray = [];
  returnData.categoryObj = {};
  returnData.groupSums = {};

  if (arrayStyleCategories && arrayStyleCategories.length) {
    for (let a = 0; a < arrayStyleCategories.length; a++) {
      let thisCategory = arrayStyleCategories[a];
      if (thisCategory && thisCategory.length && thisCategory.length > 1) {
        let thisCatGroup = thisCategory[0];
        //let thisCatMainName = thisCategory[0] + " - " + thisCategory[1];
        // Note - right now the backend may be sending just the group name as category list
        let thisCatMainName = thisCategory[1];
        // Note this structure has additional data compared to what we had before
        returnData.categoryArray.push({
          name: thisCatMainName,
          group: thisCatGroup,
        });
        returnData.categoryObj[thisCatMainName] = 0;
        if (returnData.groupSums[thisCatGroup] === undefined) {
          returnData.groupSums[thisCatGroup] = 0;
        }
      }
    }
    //-------------------------------------------------------------------------------
    return returnData;
  }
};

export const whichGroupForCategory = (categoryName, allCategoriesArray) => {
  let foundGroup = "";
  for (let a = 0; a < allCategoriesArray.length; a++) {
    let catObj = allCategoriesArray[a];
    if (catObj.name === categoryName) {
      foundGroup = catObj.group;
      a = allCategoriesArray.length;
    }
  }
  return foundGroup;
};
